	import React,{Fragment,useState,useContext, useEffect} from 'react';  
	import { set, useForm } from 'react-hook-form';
	import  useSoldTokensDelete  from '../../../hooks/useSoldTokensDelete';
	import useAllInvestnentsData from '../../../hooks/useAllInvestnentsData';
	import { soldDeleteShowModalAtom, soldDeleteModalValueAtom } from '../../../utils/jotai';
	import { useAtom, useAtomValue } from 'jotai';
	import { DevTool } from '@hookform/devtools';
	import useSoldTokensData from '../../../hooks/useSoldTokensData';
	import useProjectsData from "../../../hooks/useProjectsData"


	import {
	Button, Modal
	} from "react-bootstrap";

	const validateNumberSmThenNumber = (value, smallThen) => {
		const parsedValue = parseFloat(value);
		const parsedsmallThen = parseFloat(smallThen);
		if (isNaN(parsedValue) || parsedValue > parsedsmallThen) {
		return `must be smaller than ${parsedsmallThen}`;
		}
		return true;
	};

	function DeleteSoldModal() {
	
		const DeleteSoldform = useForm({
			mode: 'onChange'
		});

		const { register, control, handleSubmit, formState, reset: resetDeleteSoldModal, setValue } = DeleteSoldform;
		const { errors, isDirty, isSubmitted, isValid, isSubmitting, isSubmitSuccessful: isSoldDeleteSuccessful } = formState;
		const { mutate: deleteSoldToken, isError: soldDeleteIsError, isSuccess: soldDeleteIsSucces, error: soldDeleteError } = useSoldTokensDelete()
		const { data: useSoldTokensDataData, isLoading: useSoldTokensDataIsLoading, isError: useSoldTokensDataIsError, error: useSoldTokensDataError, refetch: useSoldTokensDataRefetch } = useSoldTokensData();
		const { data: useProjectsDataData, isLoading: useProjectsDataIsLoading, isError: useProjectsDataIsError, error: useProjectsDataError } = useProjectsData();

		const [sold_project_name, setSold_project_name] = useState('');

		const [soldDeleteShowModal, setSoldDeleteShowModal] = useAtom(soldDeleteShowModalAtom);
		const  soldDeleteModalValue = useAtomValue(soldDeleteModalValueAtom);
		const [soldDeleteModalErrorMsg, setSoldDeleteModalErrorMsg] = useState("");
		const [launchpad, setLaunchpad] = useState('');
		const [ammountOfTokens, setAmmountOfTokens] = useState('');

		const submitDeleteSold	= () => {
			deleteSoldToken({id: soldDeleteModalValue})
		};


		useEffect(() => {
			if (soldDeleteIsSucces) {
				setSoldDeleteShowModal(false);
			}
		}, [soldDeleteIsSucces])

		useEffect(() => {
			if (soldDeleteIsError) {
				setSoldDeleteModalErrorMsg(soldDeleteError.toString());
			}
		

			if (soldDeleteShowModal){
				
				const soldInvestment = useSoldTokensDataData[soldDeleteModalValue];
				setSold_project_name(useProjectsDataData[soldInvestment.project_id].project_name);
				// const tokens = useAllInvestmentsDataData[showSoldTokensModalID].num_of_token;
				// const solded_tokens = useAllInvestmentsDataData[showSoldTokensModalID].token_solded;
				setLaunchpad(soldInvestment.launchpad);
				const project_name = soldInvestment.project_name;
				setAmmountOfTokens(soldInvestment.ammount);
				// setSold_project_name(project_name);
				// setTokenLeftToSold(tokens);
				// setTokenLeftToSold(token_left_to_sold_str);
				//DeleteSoldform.setValue('ammount', unsolded_tokens);
				
			}


		}, [soldDeleteIsError, soldDeleteShowModal]);


		const handleShowDeleteSoldModal = (value) => {
			setSoldDeleteShowModal(value);
		}


	return (
		<div>
			<Modal size="lg" className="modal fade" id="soldDeleteShowModal" show={soldDeleteShowModal}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Are you sure tou want to delete sold?</h5>
						<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => handleShowDeleteSoldModal(false)}></Button>
					</div>
					<div className="modal-body">
							<div className="row" >
								<h4>{sold_project_name}({launchpad})</h4>
							</div>
							<div className="col-lg-4">
								<div className="form-group mb-3">
									<p><span>Deleting amount of</span><span> <b className='text-info'>{ammountOfTokens}</b></span> <span>tokens</span></p>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group mb-3">
									<button type='button' onClick={submitDeleteSold} className="submit btn btn-danger" name="submit">Delete Sold </button>
								</div>
							</div>
						<DevTool control={control} />
					</div>
				</div>
			</Modal>	
		</div>
	);
	}

	export default DeleteSoldModal;
