import { useQuery } from 'react-query';
import {request} from '../utils/axios';

const fetchClaimTokensData = () => {
    // Define the headers object
    return request({ url: '/investments/claim', method: 'get' })
}


const useClaimTokensData = () => {
    return useQuery(
        'claimTokensData', 
        fetchClaimTokensData,
        {
            staleTime: 30000, // 30 sec      
            select: (data) => {
                return data.data.data;
            } 
        },
        
    );
}

export default useClaimTokensData;