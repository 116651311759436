import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
import  ThemeContext  from "./context/ThemeContext"; 
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleOAuthProvider } from '@react-oauth/google'; 


const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
	<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<Provider store = {store}>            
					<BrowserRouter basename='/'>
						<ThemeContext>
							<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ClientID}> 
									<App />
							</GoogleOAuthProvider>
						</ThemeContext>  
					</BrowserRouter>                
				</Provider>	
				<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
			</QueryClientProvider>
	</React.StrictMode>
);
reportWebVitals();
