import { useQuery } from 'react-query';
import {request} from '../utils/axios';
import { useAtom } from 'jotai';
import { verifiedProjectsAtom } from '../utils/jotai';

const fetchProjectsData = () => {
    // Define the headers object
    return request({ url: '/investments/projects', method: 'get' })
}


const useProjectsData = () => {
    const [verifiedProjects, setVerifiedProjects] = useAtom(verifiedProjectsAtom);

    return useQuery(
        'projectsData',
        fetchProjectsData,
        {
            staleTime: 60000, // 30 sec
            refetchInterval: 30000,
            select: (data) => data?.data?.data ?? {} // Safely access nested data
        }
    );
};

export default useProjectsData;