import React from 'react';
import {invetmentEditClaiValuemAtom, claimDeleteShowModalAtom, claimDeleteModalValueAtom} from '../../../../../utils/jotai';
import { useAtom } from 'jotai';
import { Link } from "react-router-dom";
import { Tooltip } from '@chakra-ui/react'
import { formatDateString } from '../../../../../utils/validations';

function formatToThreeDecimalsIfNeeded(number, decimals = 3) {
    // Check if the number has more than the specified decimal places
    if (Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals) !== number) {
        number = number?.toFixed(decimals);
    }

    // Convert to a string for toLocaleString method
    return parseFloat(number).toLocaleString('en-US', {
        minimumFractionDigits: 0, 
        maximumFractionDigits: decimals
    });
}

const ClaimTokeRow = ({ row, projectName }) => {
    const [invetmentEditClaiValue, setInvetmentEditClaiValue] = useAtom(invetmentEditClaiValuemAtom);
    const [claimDeleteShowModal, setClaimDeleteShowModal] = useAtom(claimDeleteShowModalAtom);
    const [claimDeleteModalValue, setClaimDeleteModalValue] = useAtom(claimDeleteModalValueAtom);
    const tooltipColor = 'red'

    const handleDeleteClaim = (id)  => {
        setClaimDeleteShowModal(true);
        setClaimDeleteModalValue(id);

    }

    const handleEditClaim = (id) => {
        setInvetmentEditClaiValue(id);
    }


    return (

        <tr key={row.id}>
            <td>
                <div className="d-flex align-items-center">
                    <span className="w-space-no">{projectName}</span>
                </div>
            </td>
            <td>{row?.launchpad}</td>
            <td>{formatToThreeDecimalsIfNeeded(row.amount)}</td>
            <td>{formatDateString(row.claim_time)}</td>
            <td>
                <div className="d-flex">
                <Tooltip label='Edit Claim' placement='top' color={tooltipColor}>
                    <Link
                        onClick={()=>handleEditClaim(row.id)}
                        value={row["_id"]}
                        href="#"
                        className="btn btn-primary shadow btn-xs sharp me-1"
                    >
                        <i className="fa fa-pencil"></i>
                    </Link>
                </Tooltip>
                <Tooltip label='Delete Claim' placement='top' color={tooltipColor}>
                    <Link
                        onClick={() => handleDeleteClaim(row.id)}
                        value={row["_id"]}
                        href="#"
                        className="btn btn-danger shadow btn-xs sharp">
                        <i className="fa fa-trash"></i>
                    </Link>
                </Tooltip>
                </div>
                </td>
            </tr>
        )

    }


export default ClaimTokeRow;