import React, { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { projectSymbolFildValueAtom, selectedSymbolDataAtom, filterProjecstDataAtom, showProjectSymbolFilterSuggestionsAtom, showProjectFilterSuggestionsAtom } from '../../../../../utils/jotai';
import './AutoCompleteStyle.css'

const AutoCompleteSymbol = ({ suggestions }) => {   
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [selectedSymbolData, setSelectedSymbolData] = useAtom(selectedSymbolDataAtom);
    const [projectSymbolFildValue, setProjectSymbolFildValue] = useAtom(projectSymbolFildValueAtom);
    const [showProjectSymbolFilterSuggestions, setShowProjectSymbolFilterSuggestions ] = useAtom(showProjectSymbolFilterSuggestionsAtom);

    useEffect(() => {
        if (projectSymbolFildValue) {
            const newFilteredSuggestions = suggestions.filter(
                suggestion => suggestion.toLowerCase().includes(projectSymbolFildValue.toLowerCase())
            );
            setFilteredSuggestions(newFilteredSuggestions);
        } else {
            setFilteredSuggestions([]);
        }
        setInputValue(projectSymbolFildValue || '');
    }, [projectSymbolFildValue, suggestions]);

    const handleAutoCompleteClick = (suggestion) => () => { 
        setSelectedSymbolData([suggestion]);
    }    

    return (
        <div>
            {filteredSuggestions.length > 0 && showProjectSymbolFilterSuggestions && (
                <ul className="autocomplete-dropdown">
                    {filteredSuggestions.map(suggestion => (
                        <li 
                            key={suggestion}
                            onClick={handleAutoCompleteClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutoCompleteSymbol;
