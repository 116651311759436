import React from 'react';
import { soldDeleteShowModalAtom, soldDeleteModalValueAtom, invetmentEditSoldValuemAtom } from '../../../../../utils/jotai';
import { useAtom } from 'jotai';
import { Link } from "react-router-dom";
import { Tooltip } from '@chakra-ui/react'
import { Button } from 'react-bootstrap';
import { formatToThreeDecimalsIfNeeded, formatDateString } from '../../../../../utils/validations';


const SoldTokeRow = ({ row, projectName }) => {
    const [soldDeleteShowModal, setSoldDeleteShowModal] = useAtom(soldDeleteShowModalAtom);
    const [soldDeleteModalValue, setSoldDeleteModalValue] = useAtom(soldDeleteModalValueAtom);
    const [invetmentEditSoldValuem, setInvetmentEditSoldValuem] = useAtom(invetmentEditSoldValuemAtom);

    const tooltipColor = 'red'
    
    const handleDeleteSold = (id)  => {
        setSoldDeleteShowModal(true);
        setSoldDeleteModalValue(id);
    }

    const handleEditSold = (id)   => {
        setInvetmentEditSoldValuem(id);
    }

    function profitTextColorFunc(profit) {
        if (profit > 0) {
            return 'text-success'
        } else if (profit < 0) {
            return 'text-danger'
        } else {
            return 'text-dark'
        }
    }
    
    return (

        <tr key={row.id}>
            <td>
                <div className="d-flex align-items-center">
                    <span className="w-space-no">{projectName}</span>
                </div>
            </td>
            <td>{row?.launchpad}</td>
            <td>{formatToThreeDecimalsIfNeeded(row.amount)}</td>
            <td>${formatToThreeDecimalsIfNeeded(row.price)}</td>
            <td>${formatToThreeDecimalsIfNeeded(row.sell_usd)}</td>
            <td><span className={profitTextColorFunc(row.profit)}>${formatToThreeDecimalsIfNeeded(row.profit)}</span></td>
            <td><span className={profitTextColorFunc(row.profit)}>{formatToThreeDecimalsIfNeeded(row.profit_precent, 2)}%</span></td>
            <td>{formatDateString(row.sale_time)}</td>

            <td>
                <div className="d-flex">
                <Tooltip label='Delete Investment' placement='top' color={tooltipColor}>
                    <button
                        onClick={() => handleEditSold(row.id)}
                        type='button'
                        value={row["_id"]}
                        href="#"
                        className="btn btn-primary shadow btn-xs sharp me-1">
                        <i className="fa fa-pencil"></i>
                    </button>
                </Tooltip>
                <Tooltip label='Delete Sell' placement='top' color={tooltipColor}>
                    <button
                        onClick={() => handleDeleteSold(row.id)}
                        type='button'
                        value={row["_id"]}
                        href="#"
                        className="btn btn-danger shadow btn-xs sharp">
                        <i className="fa fa-trash"></i>
                    </button>
                </Tooltip>
                </div>
            </td>
        </tr>
        )

    }


export default SoldTokeRow;