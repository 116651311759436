import { useMutation, useQueryClient } from 'react-query';
import {nonSecureRequest} from '../utils/axios';

const loginSubmit = (loginData)  => {
    // Define the headers object
    return nonSecureRequest({ 
        url: '/auth/google/google', 
        method: 'post', 
        data: loginData,
    })
}

export const useLogin = () => {
    const queryClient = useQueryClient()
    return useMutation(loginSubmit, {
        enabled: false,
        
        onSuccess: () => Promise.all([  
            queryClient.invalidateQueries('meProfileData'),
            queryClient.invalidateQueries('InvestmentsData'),
            console.log('login Successful!!!!!!!!!!!!!!!!!')

        ])
    })
}

export default useLogin;