import { useMutation, useQueryClient } from 'react-query';
import {request} from '../utils/axios';

const investmentEdit = (investment)  => {
    // Define the headers object
    return request({ 
        url: '/investments/investment', 
        method: 'put', 
        data: investment,
    })
}


export const useInvestmentEdit = () => {
    const queryClient = useQueryClient()
    return useMutation(investmentEdit, {
        onSuccess: () => Promise.all([  
            queryClient.invalidateQueries('AllInvestmentsData'),
            queryClient.invalidateQueries('InvestmentsData')

        ])
    })
}

export default useInvestmentEdit;