import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './CustomProgressBar.css'
import { formatToThreeDecimalsIfNeeded } from '../../../../../utils/validations';

const CustomProgressBar = ({ start, current, available, end, variant="info", title="", showNumbers=true}) => {
    const currentProgress = ((current - start) / (end - start)) * 100;
    const availableProgress = ((available - start) / (end - start)) * 100;

    return (
        <div className="custom-progress-bar col-lg-12">
            <div className="progress-numbers">
                {start > 0 && showNumbers
                    ? (<span>{start}</span>)
                    : ("")
                }

                {currentProgress > 0  && showNumbers
                    ? (<span style={{ left: `${currentProgress}%` }}>{formatToThreeDecimalsIfNeeded(current)}</span>)
                    : ("")
                }

                {0 < availableProgress < end && availableProgress != currentProgress && showNumbers
                    ? (<span style={{ left: `${availableProgress}%` }}>{formatToThreeDecimalsIfNeeded(available)}</span>)
                    : ("")
                }
                { showNumbers
                    ?(<span style={{ marginLeft: 'auto' }}>{formatToThreeDecimalsIfNeeded(end)}</span>)
                    : ("")
                }
            </div>
                <ProgressBar style={{ height: '20px' }}>
                <ProgressBar variant={variant} now={currentProgress} key={1} />
                <ProgressBar variant={variant} now={availableProgress - currentProgress } key={2} style={{ opacity: 0.3 }} />
                <ProgressBar now={100 - availableProgress} key={3} />
            </ProgressBar>
            <p className="progress-title">{title}</p>
        </div>
    );
};


export default CustomProgressBar;
