import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { loadingToggleAction,loginAction, loginGoogleAction } from '../../store/actions/AuthActions';
import { useLogin } from '../../hooks/useLogin';
import { GoogleLogin } from '@react-oauth/google';  
import {jwtDecode} from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import useMeData from '../../hooks/useMe';
import { useAtom } from 'jotai';
import { userProfileAtom } from '../../utils/jotai';
//
import logo from '../../images/logo.png'
import textlogo from '../../images/logo-text.png'
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";

function Login (props) {
	  const navigate = useNavigate();
    const [email, setEmail] = useState('demo@example.com');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('123456');
    const dispatch = useDispatch();
		const { mutate: authLogin , isError: useLoginIsError, isSuccess: useLoginIsSucces, error: useLoginError, data:useLoginIsData  } = useLogin()
    const [userProfile, setUserProfile] = useAtom(userProfileAtom);
    const [erroeMsg, setSerroeMsg] = useState("");

    async function onGoogleLogin(data) { 
        dispatch(loadingToggleAction(true));	
        dispatch(loginGoogleAction(data, navigate));
    }

    useEffect(() => {
        if (useLoginIsSucces) {
            const tokenData = jwtDecode(useLoginIsData.data.data.access_token)
            const tokenDetails = {
                "kind": "identitytoolkit#GoogleResponse",
                "idToken": useLoginIsData.data.data.access_token,
                "expiresIn": tokenData.expiresIn,
            }
            saveTokenInLocalStorage(tokenDetails);
            
            navigate('/vesting-dashboard', { replace: true });
            
            window.location.reload();


        }


        if (useLoginIsError) {
            setSerroeMsg(useLoginError.toString())
            navigate('/login', { replace: true });

        }
    }, [useLoginIsSucces, useLoginIsError]);

    function onGoogleLoginNew(data) { 
      authLogin(data)
    }

    function saveTokenInLocalStorage(tokenDetails) {
      tokenDetails.expireDate = new Date(
          new Date().getTime() + tokenDetails.expiresIn * 1000,
      );
      localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
  }

    return (
        <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
            <div className="login-wrapper">
                <div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
                    <Link to="/dashboard" className="login-logo">
                        <img src={logo} alt="" />
                        <img src={textlogo} alt="" className="ms-3"/>
                      </Link>
                    <div className="login-description">
                        <h2 className="text-black  mb-2">Check the Status</h2>
                        <p className="fs-12 text-dark">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"#"}>
                              <i className="fa fa-facebook-f"></i>
                            </Link></li>
                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
                        </ul>
                        <div className="mt-5">
                            <Link to={"/policy"} className="text-black me-4">Privacy Policy</Link>
                            <Link to={"#"} className="text-black me-4">Contact</Link>
                            <Link to={"#"} className="text-black">© 2023 Altcoins Dao</Link>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                      <div className="col-xl-7 col-xxl-7">
                        <div className="authincation-content">
                          <div className="row no-gutters">
                            <div className="col-xl-12">
                              <p className='text-danger'>{erroeMsg}</p>
                              <div className="auth-form-1">
                                <h3 className="text-primary mb-1">Welcome to Altcoins Dao</h3> 
                                    <GoogleLogin 
                                        onSuccess={credentialResponse => { 
                                          onGoogleLoginNew(credentialResponse); 
                                      }} 
                                      onError={() => { 
                                        console.log('Login Failed'); 
                                      }} 
                                    /> 

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>           
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);