	import React,{Fragment,useState,useContext, useEffect} from 'react';  
	import { set, useForm } from 'react-hook-form';
	import  useInvestmentDelete  from '../../../hooks/useInvemstmentDelete';
	import useAllInvestnentsData from '../../../hooks/useAllInvestnentsData';
	import { invetmentDeleteModalValueAtom, invetmentDeleteShowModalAtom } from '../../../utils/jotai';
	import { useAtom, useAtomValue } from 'jotai';
	import { DevTool } from '@hookform/devtools';


	import {
	Button, Modal
	} from "react-bootstrap";

	function DeleteInvestmentModal() {
	
		const DeleteInvestmentform = useForm({
			mode: 'onChange'
		});
		const { register, control, handleSubmit, formState, reset: resetDeleteInvestmentModal, setValue } = DeleteInvestmentform;
		const { errors, isDirty, isSubmitted, isValid, isSubmitting, isSubmitSuccessful: isInvestmentDeleteSuccessful } = formState;
		const { mutate: deleteInvestment, isError: investmentDeleteIsError, isSuccess: investmentDeleteIsSucces, error: investmentDeleteError } = useInvestmentDelete()
		const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError } = useAllInvestnentsData();

		const [investment_project_name, setInvestment_project_name] = useState('');
		const [launchpad, setLaunchpad] = useState('');
		const [ammountOfTokens, setAmmountOfTokens] = useState('');

		const [investmentDeleteShowModal, setInvestmentDeleteShowModal] = useAtom(invetmentDeleteShowModalAtom);
		const  investmentDeleteModalValue = useAtomValue(invetmentDeleteModalValueAtom);
		const [investmentDeleteModalErrorMsg, setInvestmentDeleteModalErrorMsg] = useState("");

		const submitDeleteInvestment	= () => {
			deleteInvestment({id: investmentDeleteModalValue})
		};


		useEffect(() => {
			if (investmentDeleteIsSucces) {
				setInvestmentDeleteShowModal(false);
			}
		}, [investmentDeleteIsSucces])

		useEffect(() => {
			if (investmentDeleteIsError) {
				setInvestmentDeleteModalErrorMsg(investmentDeleteError.toString());
			}	

			if (investmentDeleteShowModal){
				const investmentInvestment = useAllInvestmentsDataData[investmentDeleteModalValue];
		 		setInvestment_project_name(investmentInvestment.project.project_name);
				setLaunchpad(investmentInvestment.launchpad);
			}


		}, [investmentDeleteIsError, investmentDeleteShowModal]);


		const handleShowDeleteInvestmentModal = (value) => {
			setInvestmentDeleteShowModal(value);
		}


	return (
		<div>
			<Modal size="lg" className="modal fade" id="investmentDeleteShowModal" show={investmentDeleteShowModal}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Are you sure tou want to delete investment?</h5>
						<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => handleShowDeleteInvestmentModal(false)}></Button>
					</div>
					<div className="modal-body">
						<form className="comment-form" onSubmit={handleSubmit(submitDeleteInvestment)} noValidate>
							<div className="row" >
								<h4>{investment_project_name}({launchpad})</h4>
							</div>
							<div className="col-lg-5">
								<div className="form-group mb-3">
									<p className='text-danger'>* All relaeted claims and sales will ce lost</p>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group mb-3">
									<button className="submit btn btn-danger" name="submit">Delete Investment </button>
								</div>
							</div>
						</form>
						<DevTool control={control} />
					</div>
				</div>
			</Modal>	
		</div>
	);
	}

	export default DeleteInvestmentModal;
