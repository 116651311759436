// CustomDatePicker.js
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Default styles
import styles from './CustomDatePicker.module.css'; // Your custom styles

const CustomDatePicker = ({ onChange, selected }) => {
    return (
        <DatePicker
            style={{ width: '100%' }}   
            className={styles.customDatePicker}
            selected={selected}
            onChange={onChange}
            placeholderText="Select date and time"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            timeCaption="Time"
        />
    );
};

export default CustomDatePicker;
