import { useMutation, useQueryClient } from 'react-query';
import {request} from '../utils/axios';

const soldTokensEdit = (sold)  => {
    console.log('sold ', sold)
    // Define the headers object
    return request({ 
        url: '/investments/sale', 
        method: 'put', 
        data: sold,
    })
}


export const useSoldTokensEdit = () => {
    const queryClient = useQueryClient()
    return useMutation(soldTokensEdit, {
        onSuccess: () => Promise.all([  
            queryClient.invalidateQueries('soldTokensData'),
            queryClient.invalidateQueries('InvestmentsData')

        ])
    })
}

export default useSoldTokensEdit;