import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useAtom } from 'jotai';
import { invetmentEditSoldValuemAtom} from '../../../../../utils/jotai';
import useSoldTokensEdit from '../../../../../hooks/useSoldTokensEdit';
import useSoldTokensData from '../../../../../hooks/useSoldTokensData';
import { Tooltip } from '@chakra-ui/react'
import { Controller } from 'react-hook-form';
import CustomDatePicker from '../DatePicker/CustomDatePicker';
import { formatToThreeDecimalsIfNeeded } from '../../../../../utils/validations';

const validateNumberSmThenNumber = (value, smallThen=null) => {
    const parsedValue = parseFloat(value);
    const parsedsmallThen = parseFloat(smallThen);
    if(smallThen === null && parsedValue <= 0) {
        return `must be bigger than 0}`; 
    }
    else if (isNaN(parsedValue) || parsedValue > parsedsmallThen) {
        return `must be smaller than ${parsedsmallThen}`;
    }
    return true;
};


const SoldTokeRowEditable = ({ row, projectName, formMethods}) => {
    const [invetmentEditSoldValue, setInvetmentEditSoldValue] = useAtom(invetmentEditSoldValuemAtom);
    const { mutate: EditTokensSubmit } = useSoldTokensEdit()
    const { data: useSoldTokensDataData } = useSoldTokensData();
    const tooltipColor = 'red'
    const { register,control ,handleSubmit, formState, reset, setValue } = formMethods;
    
    const handleCloseEditSold = () => {
        setInvetmentEditSoldValue(null);
    }

    useEffect(() => {
        formMethods.reset()
   //     formMethods.setValue('sale_time', new Date(row.sale_time))


    },[])

    function profitTextColorFunc(profit) {
        if (profit > 0) {
            return 'text-success'
        } else if (profit < 0) {
            return 'text-danger'
        } else {
            return 'text-dark'
        }
    }
    


return (

    <tr key={row.id}>
        <td>
            <div className="d-flex align-items-center">
                <span className="w-space-no">{projectName}</span>
            </div>
        </td>
        <td>{row?.launchpad}</td>
        <td>
            <input type="number" className="form-control" defaultValue={row.amount}  name="ammount" { ...register('ammount', {
                required: '* Required',
                validate: {
                    validNumber: (value) => validateNumberSmThenNumber(value)
                },
                valueAsNumber: true,
            })} />
        </td>
        <td>
            <input type="number" className="form-control" defaultValue={row.price}  name="price" { ...register('price', {
                    required: '* Required',
                    validate: {
                        validNumber: (value) => validateNumberSmThenNumber(value)
                    },
                    valueAsNumber: true,
                })} />
        </td>
        <td>{row?.sell_usd}</td>
        <td><span className={profitTextColorFunc(row.profit)}>${formatToThreeDecimalsIfNeeded(row.profit)}</span></td>
        <td><span className={profitTextColorFunc(row.profit)}>{formatToThreeDecimalsIfNeeded(row.profit_precent, 2)}%</span></td>   
        <td>
            <div className="form-group mb-3">
                <label htmlFor="sale_time" className="text-black font-w600">Date</label>	
                <div className="form-control">
                <Controller
                    name="sale_time"
                    control={control}
                    defaultValue={new Date(row.sale_time)}
                    render={({field}) => (
                        <CustomDatePicker
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                    />
                    )}
                />

                </div>
            </div>
        </td>
        <td>
            <div className="d-flex">
            <Tooltip label='Approve' placement='top' color={tooltipColor}>
                <button
                    
                    name="submit"
                    className="btn btn-success shadow btn-xs sharp me-1"
                >
                <i className="fa fa-check"></i>
                </button>
            </Tooltip>
            <Tooltip label='Cancel' placement='top' color={tooltipColor}>
                <Link
                    onClick={handleCloseEditSold}
                    value={row["_id"]}
                    href="#"
                    className="btn btn-danger shadow btn-xs sharp"
                >
                    <i className="fa fa-close"></i>
                </Link>
            </Tooltip>
            </div>
            </td>
        </tr>
    )
}


export default SoldTokeRowEditable;