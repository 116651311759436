
export function formatToThreeDecimalsIfNeeded(number, decimals = 3) {
	// Check if the number has more than the specified decimal places
	if (number === undefined || number === null || number === "" || number === 0, number === "NaN") {
		return 0;
	}
	if (Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals) !== number) {
		number = number?.toFixed(decimals);
	}
  
	// Convert to a string for toLocaleString method
	return parseFloat(number).toLocaleString('en-US', {
		minimumFractionDigits: 0, 
		maximumFractionDigits: decimals
	});
  }


export function formatDateString(dateString) {
    try{
        return new Date(dateString).toISOString().split('.')[0].replace('T', ' ');
    }catch {
        return 'N/A';
    }
}



export function revertDateFormat(formattedDateString) {
    const date = new Date(formattedDateString);
    return date.toISOString();
    
}