// export const isAuthenticated = (state) => {
//     debugger;
//     if (state.auth.auth.idToken) return true;
//     return false;
// };


export const isAuthenticated = (state) => {
    if (localStorage.getItem('userDetails')) return true;
    return false;
};