import React,{Fragment, useEffect} from 'react';
import { invetmentEditClaiValuemAtom } from '../../../../../utils/jotai';
import { useAtom } from 'jotai';
import useInvestnentsData from '../../../../../hooks/useInvestnentsData';
import useAllInvestnentsData from '../../../../../hooks/useAllInvestnentsData';
import { formatToThreeDecimalsIfNeeded } from '../../../../../utils/validations';

import {
  Col,
  Card,
  Table,
} from "react-bootstrap";



const NextVestingTable = (isLinear) => {
    const { data: useInvestmentsDataData, isLoading: useInvestmentsDataIsLoading, isError: useInvestmentsDataIsError, error: useInvestmentsDataError, isSuccess:useInvestmentsDataIsSuccess } = useInvestnentsData();
    const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError } = useAllInvestnentsData();

    const projectList = useInvestmentsDataData['projects']
    const filterRows = useInvestmentsDataData.next_vesting.filter(item => item.is_linear === isLinear.isLinear);
    const rows = filterRows.map((value) => {
        const investment = projectList[value.project_id]['list_of_investments'][value.id]
        return ({
            projectName: investment.project.project_name,
            launchpad: investment.launchpad,
            amount: investment.num_of_token,
            vestingDate: investment.next_vesting,
            daysLeft: value.days_left,

        })
    });

        return(
        <Fragment>
                <Col lg={12}>
                <Card>
                <Card.Header>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                    <thead>
                        <tr>
                        <th>
                            <strong>Project</strong>
                        </th>
                        <th>
                            <strong>Launchpad</strong>
                        </th>
                        <th>
                            <strong>Amount</strong>
                        </th>
                        <th>
                            <strong>Vesting Date</strong>
                        </th>
                        <th>
                            <strong>Days to claim</strong>
                        </th>
                        <th>
                            <strong>$ Worth</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        rows.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {item.projectName}
                                </td>
                                <td>{item.launchpad}</td>
                                <td>{formatToThreeDecimalsIfNeeded(item.amount)}</td>
                                <td>{item.vestingDate.replace('T', ' ')}</td>
                                <td>{item.daysLeft}</td>

                                <td>NA</td>
                            </tr>
                        ))
                        }
                    </tbody>
                    </Table>
                </Card.Body>
                </Card>
                </Col>
        </Fragment>
    );
};

export default NextVestingTable;