import React,{Fragment, useEffect, useState} from 'react';
import useProjectsData from "../../../../../hooks/useProjectsData"
import { invetmentEditSoldValuemAtom } from '../../../../../utils/jotai';
import { useAtom } from 'jotai';
import DeleteSoldModal from "../../DeleteSoldModal";
import SoldTokeRow from "./SoldTokeRow";
import SoldTokeRowEditable from "./SoldTokeRowEditable";
import { set, useForm } from 'react-hook-form';
import useSoldTokensEdit from '../../../../../hooks/useSoldTokensEdit';


import {    
  Col,
  Card,
  Table,
  Spinner,
} from "react-bootstrap";


const SoldTokensTable = (data) => {
    const { data: useProjectsDataData } = useProjectsData();
    const { mutate: EditTokensSubmit, isSuccess: EditTokensSubmitIsSucces, isError: EditTokensSubmitIsError, error: EditTokensSubmitError, isLoading:  EditTokensIsLoading} = useSoldTokensEdit()
    const [invetmentEditSoldValue, setInvetmentEditSoldValuemAtom] = useAtom(invetmentEditSoldValuemAtom);
    const [editErrorMsg, setEditErrorMsg] = useState(null);
    const EditSoldform = useForm({
        mode: 'onChange',
        defaultValues: {
            claim_time: null // or a specific date
            }
    });

    const { register, control, handleSubmit, formState, reset: resetEditClaim, setValue } = EditSoldform;

    const rows = Object.entries(data.data).map(([key, value]) => ({
        id: value._id,
        amount: value.ammount,  // note the misspelling in 'ammount'
        sale_time: value.sale_time,
        project_id: value.project_id,
        launchpad: value.launchpad,
        user_id: value.user_id,
        profit: value.profit,
        price: value.price,
        sell_usd: value.sell_usd,
        profit_precent: value.sell_usd/value.buy_usd  * 100 - 100,
    
    }));

    useEffect(() => {
        if (EditTokensSubmitIsError) {
            setEditErrorMsg(EditTokensSubmitError.message);
        }

    },[EditTokensSubmitIsError])

    useEffect(() => {
        if (EditTokensSubmitIsSucces) {
            setInvetmentEditSoldValuemAtom(null);
            setEditErrorMsg(null);
        }
    }, [EditTokensSubmitIsSucces]);

    const handleEditSoldSubmit = (formData) => {
        const postData = {
            ...formData,
            id: invetmentEditSoldValue,
            sale_time : formData.sale_time ? formData.sale_time.toISOString() : null,
        }
        postData.ammount = parseFloat(postData.ammount)
        postData.price = parseFloat(postData.price)
        postData.profit = parseFloat(postData.profit)
        EditTokensSubmit(postData)
    }

    
    
    return(
        <Fragment>
            <DeleteSoldModal />
            <form onSubmit={handleSubmit(handleEditSoldSubmit)} noValidate>
                <Col lg={12}>
                <Card>
                <Card.Header>
                <p className="text-danger fs-12">{editErrorMsg}</p>
                </Card.Header>
                {EditTokensIsLoading
                ? (<div className="text-center"><Spinner animation="border" variant="primary" />Loading</div>)
                : (   
                    <Card.Body>
                        <Table responsive>
                        <thead>
                            <tr>
                            <th>
                                <strong>Project</strong>
                            </th>
                            <th>
                                <strong>Launchpad</strong>
                            </th>
                            <th>
                                <strong>Amount</strong>
                            </th>
                            <th>
                                <strong>Sell Price</strong>
                            </th>
                            <th>
                                <strong>$ Sold</strong>
                            </th>
                            <th>
                                <strong>$ Profit</strong>
                            </th>
                            <th>
                                <strong>% Profit</strong>
                            </th>
                            <th>
                                <strong>Date</strong>
                            </th>
                            <th>
                                <strong>Actions</strong>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rows?.map((row) => {
                                return( 
                                    <Fragment>
                                        <DeleteSoldModal />{
                                            invetmentEditSoldValue === row.id 
                                            ?   (<SoldTokeRowEditable  formMethods={EditSoldform} row={row} projectName={useProjectsDataData[row.project_id].project_name}/>)
                                            :   (<SoldTokeRow row={row} projectName={useProjectsDataData[row.project_id].project_name} />)
                                        }
                                    </Fragment>
                                )
                                })
                            }
                        </tbody>
                        </Table>
                    </Card.Body>
                    )
                }
                </Card>
                </Col>
            </form>
        </Fragment>
    );
};

export default SoldTokensTable;