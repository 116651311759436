export function NumberColor(profit) {

    try{
        const number = parseFloat(profit)
        if (number > 0) {
            return 'text-success'
        } else if (number < 0) {
            return 'text-danger'
        } else {
            return 'text-dark'
        }
    }catch {
        return 'text-dark'
    }

    
}