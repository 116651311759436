import React,{Fragment,useState,useContext, useEffect} from 'react';  
import { Accordion } from 'react-bootstrap';
import BootstrapTable  from '../components/BootstrapTable/BootstrapTable';
import useInvestnentsData from '../../../../hooks/useInvestnentsData';
import useAllInvestnentsData from '../../../../hooks/useAllInvestnentsData';
import useClaimTokensData from '../../../../hooks/useClaimTokensData';
import useSoldTokensData from '../../../../hooks/useSoldTokensData';
import ClaimTokensTable from '../components/BootstrapTable/ClaimTokensTable'
import SoldTokensTable from '../components/BootstrapTable/SoldTokensTable'
import useProjectsData from "../../../../hooks/useProjectsData"
import DeleteInvestmentModal from "../DeleteInvestmentModal"
import CustomProgressBar from '../components/ProgressBar/ProgressBar';
import StatCard from '../components/Card/StatCard/Card';
import useMeData from '../../../../hooks/useMe';
import NextVestingTable from '../components/BootstrapTable/NextVestingTable';

import { formatToThreeDecimalsIfNeeded } from '../../../../utils/validations';

import { investmentModalAtom, filterProjecstDataAtom, filterProjectSymbolDataAtom } from '../../../../utils/jotai';
import { useAtom } from 'jotai';
import {
  Card, Col,Row,  Tab, Nav, Button, Modal
} from "react-bootstrap";
import { set } from 'react-hook-form';


function NextVesting() {
  
  const { data: useInvestmentsDataData, isLoading: useInvestmentsDataIsLoading, isError: useInvestmentsDataIsError, error: useInvestmentsDataError, isSuccess:useInvestmentsDataIsSuccess } = useInvestnentsData();
  const { data: useClaimTokensDataData, isLoading: useClaimTokensDataIsLoading, isError: useClaimTokensDataIsError, error: useClaimTokensDataError } = useClaimTokensData();
  const { data: useSoldTokensDataData, isLoading: useSoldTokensDataIsLoading, isError: useSoldTokensDataIsError, error: useSoldTokensDataError } = useSoldTokensData();
  const { data: useProjectsDataData, isLoading: useProjectsDataIsLoading, isError: useProjectsDataIsError, error: useProjectsDataError } = useProjectsData();
  const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError } = useAllInvestnentsData();
  const { data: meProfileData , isError: meProfileIsError, isSuccess: meProfileIsSucces, error: meProfileError, data:meProfileIsData, isLoading:meProfileIsLoading } = useMeData()

  const [showAddInvestmentModalAtom, setShowAddInvestmentModalAtom] = useAtom(investmentModalAtom);
  const [filterProjecstData, setFilterProjecstData] = useAtom(filterProjecstDataAtom);
  const [filterProjectSymbolData, setFilterProjectSymbolData] = useAtom(filterProjectSymbolDataAtom);

  useEffect(() => {
    if (useInvestmentsDataIsSuccess && useInvestmentsDataData && useInvestmentsDataData.projects) {
      const projectList = Object.values(useInvestmentsDataData.projects || {}).map(item => (
        `${item.project_name}::${item.project_symbol}`
      ));

      setFilterProjecstData(projectList)
      setFilterProjectSymbolData(projectList)
    }
  }, [useInvestmentsDataIsSuccess, useInvestmentsDataData]);
  
  
  const handleAddInvestmentModal = (show) => () => {
    setShowAddInvestmentModalAtom(show);
  };

  if (useInvestmentsDataIsLoading || useAllInvestmentsDataIsLoading ||useClaimTokensDataIsLoading || useSoldTokensDataIsLoading || useProjectsDataIsLoading || meProfileIsLoading ) {
    return <p>Loading...</p>;
  }
  
  if (useInvestmentsDataIsError) {
    return <p>Error: {useInvestmentsDataError.message}</p>;
  }


 
  const defaultAccordion = () => {
    const defaultAccordionData = [];
    Object.keys(useInvestmentsDataData.projects || {}).forEach((projectId) => {
      const summaryDetails = {...useInvestmentsDataData.projects[projectId]}
      const investments = useInvestmentsDataData.projects[projectId].list_of_investments;
      delete summaryDetails.list_of_investments;
      defaultAccordionData.push({
        title: summaryDetails,
        text: investments,
        bg: 'primary',

      });
    });

    return defaultAccordionData;
  };


  return (
    <div>
      <DeleteInvestmentModal />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>Next Vesting</Card.Title>
          </Card.Header>
          <Card.Body>
            <Tab.Container defaultActiveKey="Projects">
                <Row>
                <div className="col-lg-6">
                  <Nav as="ul" className="col-12 nav-pills mb-4 justify-content-start">
                    <Nav.Item as="li" key="1">
                        <Nav.Link eventKey="Projects">
                        Projects
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" key="2">
                      <Nav.Link eventKey="Linear">
                        Linear
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                    
                </div>
                    </Row>

                  <Tab.Content>
                      <Tab.Pane eventKey="Projects" key="1">
                      <NextVestingTable isLinear={false} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Linear" key="2">
                      <NextVestingTable isLinear={true}/>
                      </Tab.Pane>
                  </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Col>

    </div>
  );
}

export default NextVesting;
