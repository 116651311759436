import { useMutation, useQueryClient } from 'react-query';
import {request} from '../utils/axios';

const addInvestmentAction = (investment)  => {
    // Define the headers object
    return request({ 
        url: '/investments/investment', 
        method: 'post', 
        data: investment,
    })
}



export const useAddInvestmentData = () => {
    const queryClient = useQueryClient()
    return useMutation(addInvestmentAction,{
        onSuccess: () => Promise.all([  
            queryClient.invalidateQueries('AllInvestmentsData'),
            queryClient.invalidateQueries('InvestmentsData')

        ])
    })
}

export default useAddInvestmentData;
