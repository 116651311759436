import { atom } from 'jotai'

export const investmentModalAtom = atom(false)
export const sellTokensModalAtom = atom(false)
export const claimTokensModalAtom = atom(false)
export const claimDeleteShowModalAtom = atom(false)
export const claimDeleteModalValueAtom = atom(null)
export const soldDeleteShowModalAtom = atom(false)
export const soldDeleteModalValueAtom = atom(null)
export const claimTokenModalIDAtom = atom(null)
export const soldTokensModalAtom = atom(false)
export const soldTokenModalIDAtom = atom(null)
export const invetmentDeleteShowModalAtom = atom(false)
export const invetmentDeleteModalValueAtom = atom(null)
export const invetmentEditClaimStatusAtom = atom(false)
export const invetmentEditClaiValuemAtom = atom(null)
export const invetmentEditSoldValuemAtom = atom(false)
export const invetmentEditSoldStatusAtom = atom(false)
export const invetmentEditModalValueAtom = atom(null)
export const invetmentDuplicateModalValueAtom = atom(null)
export const projectNameFildValueAtom = atom(null)
export const filterProjecstDataAtom = atom([])
export const selectedProjecstDataAtom = atom(null)
export const filterProjectSymbolDataAtom = atom([])
export const projectSymbolFildValueAtom = atom(null)
export const selectedSymbolDataAtom = atom(null)
export const showProjectFilterSuggestionsAtom= atom(false)
export const showProjectSymbolFilterSuggestionsAtom= atom(false)
export const userProfileAtom = atom({})



export const verifiedProjectsAtom = atom({})