import React,{Fragment,useRef,useContext, useEffect, useReducer, useState} from 'react';
import { Button, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { soldTokenModalIDAtom, soldTokensModalAtom, investmentModalAtom, claimTokensModalAtom, claimTokenModalIDAtom, invetmentDeleteShowModalAtom, invetmentDeleteModalValueAtom, invetmentEditModalValueAtom, invetmentDuplicateModalValueAtom} from '../../../../../utils/jotai';
import { formatToThreeDecimalsIfNeeded } from '../../../../../utils/validations';
import { NumberColor } from '../../../../../utils/formating';
import { useAtom } from 'jotai';
import { Tooltip } from '@chakra-ui/react'
import { formatDateString } from '../../../../../utils/validations';
import CustomProgressBar from '../ProgressBar/ProgressBar';
import useAllInvestnentsData  from '../../../../../hooks/useAllInvestnentsData';

import {
  Col,
  Card,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";


const initialState = false;
const reducer = (state, action) =>{
    switch (action.type){
        default:
        return state	
    }	
}

const BootstrapTable = (data) => {
    const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError, refetch:useAllInvestmentsDataRefetch } = useAllInvestnentsData();
    const [showSellTokensModal, setShowSellTokensModal] = useAtom(soldTokensModalAtom);
    const [soldTokenModalAtomID, setSoldTokenModalAtomID] = useAtom(soldTokenModalIDAtom);
    const [showClaimTokensModalID, setShowClaimTokensModalID] = useAtom(claimTokenModalIDAtom);
    const [showClaimTokensModal, setShowClaimokensModal] = useAtom(claimTokensModalAtom);
    const [invetmentDeleteModalValue, setInvetmentDeleteModalValue] = useAtom(invetmentDeleteModalValueAtom);
    const [invetmentDeleteShowModal, setInvetmentDeleteShowModal] = useAtom(invetmentDeleteShowModalAtom);
    const [invetmentEditModalValue, setInvetmentEditModalValue] = useAtom(invetmentEditModalValueAtom);
    const [invetmentDuplicateModalValue, setInvetmentDuplicateModalValue] = useAtom(invetmentDuplicateModalValueAtom);
    const [investmentModal, setInvestmentModal] = useAtom(investmentModalAtom);
    const [claimProgressBar, setClaimProgressBar] = useState({
        start: 0,
        end: 0,
        available: 0,
        current: 0,
    });

    const tooltipColor = 'red'
    const claimButtonRef = useRef(null);

    useEffect(() => {
		if (invetmentEditModalValue) {
			setInvestmentModal(true);
            
		}
	
	}, [invetmentEditModalValue]);

    const handleInputChange = (event) => {
	
	  };

    function submitClaim(event) {
        const claimButtonvalue = claimButtonRef.current.value;
    }

    const handleShowClsimTokensModal = (value, id) => () => {
		setShowClaimokensModal(value);
        if (value && id !== undefined) {
            setShowClaimTokensModalID(id);
        }
	}

    const handleShowSellTokensModal = (value, id) => () => {
		setShowSellTokensModal(value);
        if (value && id !== undefined) {
            setSoldTokenModalAtomID(id);
        }
    
	}


    const handleShowDeleteInvestmentModal = (value, id) => () => {
		setInvetmentDeleteShowModal(value);
        if (value && id !== undefined) {
            setInvetmentDeleteModalValue(id);
        }
    
	}

    const handleShowDuplicatedInvestmentModal = (id) => () => {
        setInvetmentDuplicateModalValue(id);
    }

    const handleShowEditInvestmentModal = (id) => () => {
        setInvetmentEditModalValue(id);
	}

    const rowData= Object.values(data.data)
    const titleData= data.titleData
    const { changeBackground } = useContext(ThemeContext);
	const [state, dispatch] = useReducer(reducer, initialState);

    

    return(
        <Fragment>
            <Col lg={12}>
            <Card>
            <Card.Body>
                <Table responsive>
                <thead>
                    <tr key="key">
                    <th>
                        <strong>Launchpad</strong>
                    </th>
                    <th>
                        <strong>Round</strong>
                    </th>
                    <th>
                        <strong># Of Token</strong>
                    </th>
                    <th>
                        <strong>$ invested</strong>
                    </th>
                    <th>
                        <strong>Price</strong>
                    </th>
                    <th>
                        <strong>Vesting Plan</strong>
                    </th>
                    <th>
                        <strong># Claims Left</strong>
                    </th>
                    <th>
                        <strong>Next Vesting</strong>
                    </th>
                    <th>
                        <strong>Last Vesting</strong>
                    </th>
                    <th>
                        <strong>Actions</strong>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rowData.map((row, index) => {
                           return( 
                            <Fragment>
                                <Modal size="lg" className="modal fade" show={state.addClaim} onHide={()=>dispatch({type:'addClaim'})}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Claim</h5>
                                        <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'closeClaim'})}></Button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="comment-form" onSubmit={submitClaim }>
                                            <div className="row">
                                                <h4>Project details </h4>
                                                <div className="col-lg-4">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="name" className="text-black font-w600">  Project Name  <span className="required">*</span> </label>
                                                        <input type="text" className="form-control" defaultValue={1} name="projectName" placeholder="ProjectName" onChange={handleInputChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="symbol" className="text-black font-w600"> Symbol <span className="required">*</span></label>
                                                        <input type="text" className="form-control" defaultValue={1} placeholder="ABC" name="projectSymbol" onChange={handleInputChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="price" className="text-black font-w600"> Price <span className="required">*</span></label>
                                                        <input type="text" className="form-control" defaultValue={1} name="price" onChange={handleInputChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-3">
                                                        <input type="submit" value="Add Claim" className="submit btn btn-primary" name="submit"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                </Modal>
                                <tr key={row._id}>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="w-space-no">{row.launchpad}</span>
                                        </div>
                                    </td>
                                    <td>{row.round}</td>
                                    <td>{formatToThreeDecimalsIfNeeded(row.num_of_token)}</td>
                                    <td>${formatToThreeDecimalsIfNeeded(row.invested_usd)}</td>
                                    <td>{formatToThreeDecimalsIfNeeded(row.price, 4)}</td>
                                    <td>
                                        {row.vested_on_tge}% on TGE, {row.cliff} Month Cliff, {row.release_number} {row.release_units}
                                    </td>
                                    <td>{row.total_vesting_intervals_left || 0}</td>
                                    
                                    <td>{row?.next_vesting === 'Over' ? 'Over' : formatDateString(row?.next_vesting)}</td>
                                    <td>{row?.next_vesting === 'Over' ? 'Over' : formatDateString(row?.last_vesting)}</td>
                                    <td>
                                        <div className="d-flex">
                                        <Tooltip label='Claim' placement='top' color={tooltipColor}>
                                            <Link
                                                onClick={handleShowClsimTokensModal(true, row["_id"])}
                                                value={row["_id"]}
                                                href="#"
                                                className={`btn btn-info shadow btn-xs sharp me-1${row.available_tokens_left_to_claim === 0 ? ' disabled' : ''}`}
                                            >
                                                <i className="fa fa-info"></i>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip label='Sell' placement='top' color={tooltipColor}>
                                            <Link
                                                onClick={handleShowSellTokensModal(true, row["_id"])}
                                                value={row["_id"]}
                                                href="#"
                                                className={`btn btn-success shadow btn-xs sharp me-1${row.available_tokens_left_to_sell === 0 ? ' disabled' : ''}`}
                                            >
                                                <i className="fa fa-dollar"></i>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip label='Edi' placement='top' color={tooltipColor}>
                                            <Link
                                                onClick={handleShowEditInvestmentModal(row["_id"])}
                                                value={row["_id"]}
                                                href="#"
                                                className="btn btn-primary shadow btn-xs sharp me-1"
                                            >
                                                <i className="fa fa-pencil"></i>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip label='Clone' placement='top' color={tooltipColor}>
                                            <Link
                                                onClick={handleShowDuplicatedInvestmentModal(row["_id"])}
                                                value={row["_id"]}
                                                href="#"
                                                className="btn btn-success shadow btn-xs sharp"
                                            >
                                                <i className="fa fa-clone"></i>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip label='Delete Investment' placement='top' color={tooltipColor}>
                                            <Link
                                                onClick={handleShowDeleteInvestmentModal(true, row["_id"])}
                                                value={row["_id"]}
                                                href="#"
                                                className="btn btn-danger shadow btn-xs sharp"
                                            >
                                                <i className="fa fa-trash"></i>
                                            </Link>
                                        </Tooltip>
                                        </div>
                                        </td>
                                </tr>
                                <tr key={`${row._id}end`}>
                                    <td colspan="10">
                                        <div className="col-lg-12 d-flex">
                                            <div className="col-lg-2">
                                                <CustomProgressBar  start={0} current={row.total_claimed} end={row.num_of_token} available={row.available_tokens_left_to_claim}  title='Claimed' showNumbers={false} />
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-2">
                                                <CustomProgressBar  start={0} end={row.num_of_token} available={row.available_tokens_left_to_sell} current={row.total_sold} variant='success' title='Sold'  showNumbers={false}/>
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="text-center border-bx col-lg-2">
                                                <span>Profit</span>
                                                <p className={`mb-0 pt-1 font-w500 ${NumberColor(row?.total_profit)}`}>{formatToThreeDecimalsIfNeeded(row.total_profit) || 0}</p>
                                            </div>
                                            <div className="text-center border-bx col-lg-2">
                                                <span>UR. Profit</span>
                                                <p className={`mb-0 pt-1 font-w500  ${NumberColor(row?.ur_profit)}`}>{formatToThreeDecimalsIfNeeded(row?.ur_profit) || 0}</p>
                                            </div>
                                            <div className="text-center border-bx col-lg-2">
                                                <span>T. Profit</span>
                                                <p className={`mb-0 pt-1 font-w500  ${NumberColor(row?.ur_profit + row.total_profit)}`}>{formatToThreeDecimalsIfNeeded(row?.ur_profit + row.total_profit) || 0}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </Fragment>
                           )
                           })  
                    }
                </tbody>
                </Table>
            </Card.Body>
            </Card>
            </Col>
        </Fragment>
    );
};

export default BootstrapTable;