import { useQuery } from 'react-query';
import {request} from '../utils/axios';
import { jotai } from '../utils/jotai';
import { useAtom } from 'jotai';
import { userProfileAtom } from '../utils/jotai';

const fetchMeData = () => {
    // Define the headers object
    return request({ url: 'users/me', method: 'get' })
}


const useMeData = () => {
    const [userProfile, setUserProfile] = useAtom(userProfileAtom);
    return useQuery(
        'meProfileData', 
        fetchMeData,
        {
            staleTime: 300 * 1000, // 300 sec
            select: (data) => {
                return data.data;
            },
            onSuccess: (data) => {
                setUserProfile(data.data)
            },    
        },
        
    );
}

export default useMeData;