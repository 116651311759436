	import React,{Fragment,useState,useContext, useEffect} from 'react';  
	import { set, useForm } from 'react-hook-form';
	import  useClaimDelete  from '../../../hooks/useClaimTokensDelete';
	import useAllInvestnentsData from '../../../hooks/useAllInvestnentsData';
	import { claimDeleteShowModalAtom, claimDeleteModalValueAtom } from '../../../utils/jotai';
	import { useAtom, useAtomValue } from 'jotai';
	import { DevTool } from '@hookform/devtools';
	import useClaimTokensData from '../../../hooks/useClaimTokensData';
	import useProjectsData from "../../../hooks/useProjectsData"


	import {
	Button, Modal
	} from "react-bootstrap";

	const validateNumberSmThenNumber = (value, smallThen) => {
		const parsedValue = parseFloat(value);
		const parsedsmallThen = parseFloat(smallThen);
		if (isNaN(parsedValue) || parsedValue > parsedsmallThen) {
		return `must be smaller than ${parsedsmallThen}`;
		}
		return true;
	};

	function DeleteClaimModal() {
	
		const DeleteClaimform = useForm({
			mode: 'onChange'
		});
		const { register, control, handleSubmit, formState, reset: resetDeleteClaimModal, setValue } = DeleteClaimform;
		const { errors, isDirty, isSubmitted, isValid, isSubmitting, isSubmitSuccessful: isClaimDeleteSuccessful } = formState;
		const { mutate: deleteClaim, isError: claimDeleteIsError, isSuccess: claimDeleteIsSucces, error: claimDeleteError } = useClaimDelete()
		const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError } = useAllInvestnentsData();
		const { data: useClaimTokensDataData, isLoading: useClaimTokensDataIsLoading, isError: useClaimTokensDataIsError, error: useClaimTokensDataError, refetch: useClaimTokensDataRefetch } = useClaimTokensData();
		const { data: useProjectsDataData, isLoading: useProjectsDataIsLoading, isError: useProjectsDataIsError, error: useProjectsDataError } = useProjectsData();

		const [claim_project_name, setClaim_project_name] = useState('');
		const [unclaimedTokens, setUnclaimedTokens] = useState('');
		const [tokenLeftToClaim, setTokenLeftToClaim] = useState('');
		const [launchpad, setLaunchpad] = useState('');
		const [ammountOfTokens, setAmmountOfTokens] = useState('');

		const [claimDeleteShowModal, setClaimDeleteShowModal] = useAtom(claimDeleteShowModalAtom);
		const  claimDeleteModalValue = useAtomValue(claimDeleteModalValueAtom);
		const [claimDeleteModalErrorMsg, setClaimDeleteModalErrorMsg] = useState("");

		const submitDeleteClaim	= () => {
			deleteClaim({id: claimDeleteModalValue})
		};


		useEffect(() => {
			if (claimDeleteIsSucces) {
				setClaimDeleteShowModal(false);
			}

			if (claimDeleteIsError) {
				setClaimDeleteModalErrorMsg(claimDeleteError.toString());
			}
		}, [claimDeleteIsSucces, claimDeleteIsError])

		

		useEffect(() => {
			if (claimDeleteShowModal){			
				const claimInvestment = useClaimTokensDataData[claimDeleteModalValue];
				setClaim_project_name(useProjectsDataData[claimInvestment.project_id].project_name);
				setLaunchpad(claimInvestment.launchpad);
				const project_name = claimInvestment.project_name;
				setAmmountOfTokens(claimInvestment.ammount);
			}


		},  [claimDeleteShowModal]);


		const handleShowDeleteClaimModal = (value) => {
			setClaimDeleteShowModal(value);
		}


	return (
		<div>
			<Modal size="lg" className="modal fade" id="claimDeleteShowModal" show={claimDeleteShowModal}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Are you sure tou want to delete claim?</h5>
						<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => handleShowDeleteClaimModal(false)}></Button>
					</div>
					<div className="modal-body">
							<div className="row" >
								<h4>{claim_project_name}({launchpad})</h4>
							</div>
							<div className="col-lg-4">
								<div className="form-group mb-3">
									<p><span>Deleting amount of</span><span> <b className='text-info'>{ammountOfTokens}</b></span> <span>tokens</span></p>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group mb-3">
									<button className="submit btn btn-danger" type="button" onClick={submitDeleteClaim} name="submit">Delete Claim </button>
								</div>
							</div>
						<DevTool control={control} />
					</div>
				</div>
			</Modal>	
		</div>
	);
	}

	export default DeleteClaimModal;
