import React,{Fragment,useState,useContext, useEffect} from 'react';
import { ThemeContext } from "../../../context/ThemeContext";
import NextVesting from './data/NextVesting';


const NextVestingPage = () => {

	// add investment data hook
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
	}, []);
		return(
			<Fragment>
				<NextVesting/>
			</Fragment>			
		)
	
}
export default NextVestingPage;