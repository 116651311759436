import React,{Fragment,useState,useContext, useEffect} from 'react';  
import { useForm, Controller } from 'react-hook-form';
import {useAddInvestmentData} from '../../../hooks/useAddInvestmentData';
import {useInvestmentEdit} from '../../../hooks/useInvestmentEdit';
import useAllInvestnentsData from '../../../hooks/useAllInvestnentsData';
import { investmentModalAtom , invetmentEditModalValueAtom, projectNameFildValueAtom,showProjectFilterSuggestionsAtom,showProjectSymbolFilterSuggestionsAtom, projectSymbolFildValueAtom, filterProjecstDataAtom, selectedProjecstDataAtom, selectedSymbolDataAtom, filterProjectSymbolDataAtom, invetmentDuplicateModalValueAtom} from '../../../utils/jotai';
import { useAtom, useAtomValue } from 'jotai';
import { DevTool } from '@hookform/devtools';
import CustomDatePicker from './components/DatePicker/CustomDatePicker';
import AutoComplete from './components/AutoComplete/AutoComplete';
import AutoCompleteSymbol from './components/AutoComplete/AutoCompleteSymbol';

import {
  Button, Modal
} from "react-bootstrap";

const validateNumberGtThenZiro = (value) => {
	const parsedValue = parseFloat(value);
	if (isNaN(parsedValue) || parsedValue <= 0) {
	  return `must be greater than 0`;
	}
	return true;
  };

  const validateNumber = (value) => {
	const parsedValue = parseFloat(value);
	if (isNaN(parsedValue) || parsedValue < 0) {
	  return `must be a number`;
	}
	return true;
  };

function AddInvestmentModal() {
  
	const form = useForm({
		defaultValues: {
			fee: 0,
			cliff: 0,
			tge_date: new Date(),
		},
		mode: 'onChange'
	});
	const { register, control, watch , handleSubmit, formState, reset: resetAddInvesmentModal } = form;
	const { errors, isDirty, isSubmitted, isValid, isSubmitting, isSubmitSuccessful } = formState;
	const { mutate: addInvestment, isError: addInvestmentIsError, isSuccess: addInvestmentIsSucces, error: addInvestmentError } = useAddInvestmentData()
	const { mutate: editInvestment, isError: editInvestmentIsError, isSuccess: editInvestmentIsSucces, error: editInvestmentError } = useInvestmentEdit()	
	const [invetmentEditModalValue, setInvetmentEditModalValue] = useAtom(invetmentEditModalValueAtom);
	const [filterProjecstData, setFilterProjecstData] = useAtom(filterProjecstDataAtom);
    const [selectedProjecstData, setSelectedProjecstData ] = useAtom(selectedProjecstDataAtom);
    const [filterProjectSymbolData, setFilterProjectSymbolData ] = useAtom(filterProjectSymbolDataAtom);
    const [showProjectSymbolFilterSuggestions, setShowProjectSymbolFilterSuggestions ] = useAtom(showProjectSymbolFilterSuggestionsAtom);

	const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError, refetch:useAllInvestmentsDataRefetch } = useAllInvestnentsData();

    const [showAddInvestmentModalAtom, setShowAddInvestmentModalAtom] = useAtom(investmentModalAtom);
	const [modalErrorMsg, setModalerorMsg] = useState("");
    const [startDate, setStartDate] = useState(new Date());
	const [projectNameFildValue, setProjectNameFildValue] = useAtom(projectNameFildValueAtom);
	const [projectSymbolFildValue, setProjectSymbolFildValue] = useAtom(projectSymbolFildValueAtom);
	const [invetmentDuplicateModalValue, setInvetmentDuplicateModalValue] = useAtom(invetmentDuplicateModalValueAtom);
	const [selectedSymbolData, setSelectedSymbolData] = useAtom(selectedSymbolDataAtom);
    const [showProjectFilterSuggestions, setshowProjectFilterSuggestions ] = useAtom(showProjectFilterSuggestionsAtom);

	const watchedProjectName = watch('project_name');
	const watchedProjecSymbol = watch('project_symbol');
	const watchedInvestedUsd = watch('invested_usd');
	const watchedPrice = watch('price');
	const watchedNumOfTokens = watch('num_of_token');
	const watchedFee = watch('fee');

	const submitInvestment 	= (formData) => {
		if (formData.tge_date){
			formData.tge_date = formData.tge_date.toISOString()
		}else{
			formData.tge_date = ""
		}
		const postData = {
			...formData,			
		}

		if (invetmentEditModalValue){
			const postData = {
				...formData,
				
				id: invetmentEditModalValue
			}
			editInvestment(postData)
		}else{
			addInvestment(postData)
		}
	};

	useEffect(() => {
		if (watchedNumOfTokens && watchedInvestedUsd){
			const netInvestment = form.getValues('invested_usd') * (1 - form.getValues('fee') / 100);
			const pricePerToken = netInvestment / form.getValues('num_of_token');
			form.setValue('price', pricePerToken);
			return
		}
		
		
		if(watchedInvestedUsd && watchedPrice){
			const netInvestment = form.getValues('invested_usd') * (1 - form.getValues('fee') / 100);
			const totalTokens = netInvestment / form.getValues('price');
			form.setValue('num_of_token', totalTokens);
			return
		}
	}, [watchedFee])

	useEffect(() => {
		if (watchedNumOfTokens && watchedInvestedUsd){
			const netInvestment = form.getValues('invested_usd') * (1 - form.getValues('fee') / 100);
			const pricePerToken = netInvestment / form.getValues('num_of_token');
			form.setValue('price', pricePerToken);
			return
		}


	},  [watchedNumOfTokens, watchedInvestedUsd])

	useEffect(() => {

			if(watchedInvestedUsd && watchedPrice){
				const netInvestment = form.getValues('invested_usd') * (1 - form.getValues('fee') / 100);
				const totalTokens = netInvestment / form.getValues('price');
				form.setValue('num_of_token', totalTokens);
				return
			}


	}, [watchedInvestedUsd, watchedPrice])

	useEffect(() => {
		if (watchedProjectName && !invetmentEditModalValue ){
			
			setProjectNameFildValue(form.getValues('project_name'))
			setshowProjectFilterSuggestions(true)
		}else{
			setProjectNameFildValue(null)
		}
	}, [watchedProjectName])

	useEffect(() => {
		if (watchedProjecSymbol  && !invetmentEditModalValue ){
			
			setProjectSymbolFildValue(form.getValues('project_symbol'))
			setShowProjectSymbolFilterSuggestions(true)		
		}else{
			setProjectSymbolFildValue(null)
		}
	}, [watchedProjecSymbol])

	useEffect(() => {
		if (editInvestmentIsSucces) {
			resetAddInvesmentModal();
			setShowAddInvestmentModalAtom(false);
			setInvetmentEditModalValue(null)

		}
	}, [editInvestmentIsSucces])

	
	useEffect(() => {
		if (invetmentEditModalValue) {
			const editInvestment = useAllInvestmentsDataData[invetmentEditModalValue]
			
			form.setValue('project_name', editInvestment.project.project_name);
			form.setValue('project_symbol', editInvestment.project_symbol);
			form.setValue("chain", editInvestment.chain);
			form.setValue("price", editInvestment.price);
			form.setValue("launchpad", editInvestment.launchpad);
			form.setValue("round", editInvestment.round);
			form.setValue("fee", editInvestment.fee);
			form.setValue("invested_usd", editInvestment.invested_usd);
			form.setValue("num_of_token", editInvestment.num_of_token);
			if (editInvestment.tge_date){
				form.setValue("tge_date", new Date(editInvestment.tge_date));
			}
			form.setValue("vested_on_tge", editInvestment.vested_on_tge);
			form.setValue("cliff", editInvestment.cliff);
			form.setValue("release_units", editInvestment.release_units);
			form.setValue("release_number", editInvestment.release_number);
			form.setValue("wallet", editInvestment.wallet);
			form.setValue("project_contract", editInvestment.project_contract);
			form.setValue("transaction_txn", editInvestment.transaction_txn);
			form.setValue("cliam_link", editInvestment.cliam_link);
			form.setValue("airdrop", editInvestment.airdrop);
			form.setValue("is_linear", editInvestment.is_linear);
			form.setValue("comments", editInvestment.comments);
			setShowProjectSymbolFilterSuggestions(false)
			setshowProjectFilterSuggestions(false)

		}

	}, [invetmentEditModalValue])


	useEffect(() => {
		if (invetmentDuplicateModalValue) {
			setShowAddInvestmentModalAtom(true);
			console.log('invetmentDuplicateModalValue',invetmentDuplicateModalValue)

			const editInvestment = useAllInvestmentsDataData[invetmentDuplicateModalValue]
			
			form.setValue('project_name', editInvestment.project.project_name);
			form.setValue('project_symbol', editInvestment.project_symbol);
			form.setValue("chain", editInvestment.chain);
			form.setValue("project_contract", editInvestment.project_contract);
			if (editInvestment.tge_date){
				form.setValue("tge_date", new Date(editInvestment.tge_date));
			}
			form.setValue("price", editInvestment.price);
			form.setValue("vested_on_tge", editInvestment.vested_on_tge);
			setShowProjectSymbolFilterSuggestions(false)
			setshowProjectFilterSuggestions(false)

		}

	}, [invetmentDuplicateModalValue])



	useEffect(() => {
		if (addInvestmentIsError) {
		  setModalerorMsg(addInvestmentError.toString());
		}
	  
		if (addInvestmentIsSucces) {
		  resetAddInvesmentModal();
		  setShowAddInvestmentModalAtom(false);
		  
		}

		

	  }, [addInvestmentIsError, addInvestmentIsSucces]);


	useEffect(() => {
		if (selectedProjecstData?.length > 0){
			const [selectedProjecst, selectedSymbole] = selectedProjecstData[0].split('::');
			form.setValue('project_name', selectedProjecst);
			form.setValue('project_symbol', selectedSymbole);	
			setshowProjectFilterSuggestions(false)
			setShowProjectSymbolFilterSuggestions(false)
		}

		if (selectedSymbolData?.length > 0){
			const [selectedProjecst, selectedSymbole] = selectedSymbolData[0].split('::');
			form.setValue('project_name', selectedProjecst);
			form.setValue('project_symbol', selectedSymbole);	
			setshowProjectFilterSuggestions(false)
			setShowProjectSymbolFilterSuggestions(false)
			
		}
		
		
		//setFilterProjecstData([])
		//setFilterProjectSymbolData([])
		
	}, [selectedProjecstData, selectedSymbolData])

	const handleAddInvestmentModal = (value) => () => {
		setShowAddInvestmentModalAtom(value);
	}

	const handleCloseInvestmentModal = () => () => {
		setInvetmentEditModalValue(null)
		setInvetmentDuplicateModalValue(null)
		form.reset()
		setShowAddInvestmentModalAtom(false);
	}

	const isFiledDisabled = () => {
		return invetmentEditModalValue ? true : false
	}


  return (
    <div>
      <Modal size="lg" className="modal fade" show={showAddInvestmentModalAtom}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Investment Project</h5>
						<Button variant="" type="button" className="btn-close text text-white" data-dismiss="modal" onClick={handleCloseInvestmentModal()}></Button>
					</div>
					<div className="modal-body">
						<form className="comment-form" onSubmit={ handleSubmit(submitInvestment)} noValidate>
							<p className="text-danger fs-12">{modalErrorMsg}</p>
							<div className="row">
								<h4>Project details </h4>
								<div className="col-lg-4">
									<div className="form-group mb-3">
										<label htmlFor="name"  className="text-black font-w600">  Project Name  <span className="required text-danger">*</span>  </label>
										<input type="text" disabled={isFiledDisabled()}  className="form-control" name="project_name" placeholder="ProjectName" {...register("project_name", {
											required: '* required',
										})}/>
										<AutoComplete  suggestions={filterProjecstData}/>
										<p className="text-danger fs-12">{errors.projectName?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="symbol" className="text-black font-w600"> Symbol <span className="required text-danger">*</span> </label>
										<input type="text" disabled={isFiledDisabled()} className="form-control"  placeholder="ABC" name="project_symbol" {...register("project_symbol", {
											required: '* required'
										})}/>
										<AutoCompleteSymbol  suggestions={filterProjecstData}/>
										<p className="text-danger fs-12">{errors.projectSymbol?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<label htmlFor="chain" className="text-black font-w600"> Chain <span className="required text-danger">*</span> </label>
									<div className="form-group mb-3">
										<select
										disabled={isFiledDisabled()}
										className="form-control form-control-lg"
										{...register("chain")}
										name="chain"
										>
										<option>BSC</option>
										<option>ETH</option>
										<option>Matic</option>
										<option>ARB</option>
										</select>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="price" className="text-black font-w600"> Price <span className="required text-danger">*</span> </label>
										<input type="number"  className="form-control"  name="price" {...register("price",{
											required: '* required',
											valueAsNumber: true,
											validate: {
												validNumber: (value) => validateNumberGtThenZiro(value),
											},
										})}/>
										<p className="text-danger fs-12">{errors.price?.message}</p>
									</div>
								</div>
								<hr/>
								<h4>Investment details</h4>
								<div className="col-lg-3">
									<div className="form-group mb-3">
										<label htmlFor="launchpad" className="text-black font-w600"> Launchpad <span className="required text-danger">*</span> </label>
										<input type="text" className="form-control"  {...register("launchpad", {
											required: '* required',
										})} name="launchpad"/>
										<p className="text-danger fs-12">{errors.launchpad?.message}</p>
									</div>
								</div>
								<div className="col-lg-3">
									<label htmlFor="round" className="text-black font-w600"> Round <span className="required text-danger">*</span> </label>
									<div className="form-group mb-3">
										<select
										{...register("round")}
										className="form-control form-control-lg"
										name='round'
										>
										<option>Public</option>
										<option>Private</option>
										<option>Stratigic</option>
										<option>Pre Seed</option>
										<option>Seed</option>
										<option>Angels</option>
										</select>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="fee" className="text-black font-w600">% Fee</label>
										<input type="number	" className="form-control" {...register("fee", {
											required: '* required',
											valueAsNumber: true,

										})}  placeholder="Fee" name="fee"/>
										<p className="text-danger fs-12">{errors.fee?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="investment" className="text-black font-w600"> $ Invested<span className="required text-danger">*</span> </label>
										<input type="number" className="form-control" {...register("invested_usd", {
											valueAsNumber: true,
											required: '* required',
											validate: {
												validNumber: (value) => validateNumberGtThenZiro(value),
											},
										})}  placeholder="Investments" name="invested_usd"/>
										<p className="text-danger fs-12">{errors.invested_usd?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="tokens" className="text-black font-w600"># of Tokens<span className="required text-danger">*</span> </label>
										<input type="number" className="form-control" placeholder="Tokens" name="num_of_token" {...register("num_of_token", {
											required: '* required',
											validate: {
												validNumber: (value) => validateNumberGtThenZiro(value),
											},
											valueAsNumber: true
										})} />
										<p className="text-danger fs-12">{errors.num_of_token?.message}</p>
									</div>
								</div>
								<hr/>
								<h4>Vesting Plan</h4>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="tgedate" className="text-black font-w600">TGE DTE</label>	
										<div className="form-control">
										<Controller
										name="tge_date"
										control={control}
										render={({field}) => (
											<CustomDatePicker
											selected={field.value}
											onChange={(date) => field.onChange(date)}
										/>
										)}
									/>

										</div>
										<p className="text-danger fs-12">{errors.tgeDate?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="vestontge" className="text-black font-w600">% On TGE<span className="required text-danger">*</span> </label>
										<input type="number" className="form-control" {...register("vested_on_tge",{
											required: '* required',
											validate: {
												validNumber: (value) => validateNumber(value),
											},
											valueAsNumber: true,
										})}  name="vested_on_tge"/>
										<p className="text-danger fs-12">{errors.vested_on_tge?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="clif" className="text-black font-w600">Clif/Lock</label>
										<input type="number" className="form-control" {...register("cliff", {
											required: '* required',
											valueAsNumber: true,
											validate: {
												validNumber: (value) => validateNumber(value),
											},
										})}  name="cliff"/>
									</div>
									<p className="text-danger fs-12">{errors.cliff?.message}</p>
								</div>
								<div className="col-lg-3	">
									<label htmlFor="releaseunit" className="text-black font-w600"> Release Unit <span className="required text-danger">*</span> </label>
									<div className="form-group mb-3">
										<select
										defaultValue="Month"
										{...register("release_units")} 
										className="form-control form-control-lg"
										name='release_units'
										>
										<option>Day</option>
										<option>Month</option>
										<option>Year</option>
										</select>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="form-group mb-3">
										<label htmlFor="releasenumber" className="text-black font-w600">Release Number<span className="required text-danger">*</span> </label>
										<input type="number" className="form-control"  {...register("release_number", {
											required: '* required',
											validate: {
												validNumber: (value) => validateNumberGtThenZiro(value),
											},
											valueAsNumber: true,
										})} name="release_number"/>
										<p className="text-danger fs-12">{errors.release_number?.message}</p>
									</div>
								</div>
								<div className="col-xl-1 col-xxl-1 col-1">
									<div className="form-group mb-3">
										<label
											className="form-check-label"
											htmlFor="linear"
											>
											Linear
										</label>
										<div className="form-check custom-checkbox mb-3 checkbox-success">
											<input
											type="checkbox"
											className="form-check-input"
											id="linear"
											{...register("is_linear", {})}
											/>
								
										</div>
									</div>
								</div>
								
								<hr/>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="wallet" className="text-black font-w600"> Wallet</label>
										<input type="number	" className="form-control" {...register("wallet", {})}  placeholder="wallet" name="wallet"/>
										<p className="text-danger fs-12">{errors.wallet?.message}</p>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="project_contract" className="text-black font-w600"> Project Contract</label>
										<input type="number	" className="form-control" {...register("project_contract", {})}  placeholder="project_contract" name="project_contract"/>
										<p className="text-danger fs-12">{errors.wallet?.message}</p>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="transaction_txn" className="text-black font-w600"> Investing Transaction ID</label>
										<input type="number	" className="form-control" {...register("transaction_txn", {})}  placeholder="transaction_txn" name="transaction_txn"/>
										<p className="text-danger fs-12">{errors.wallet?.message}</p>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="cliam_link" className="text-black font-w600"> Claim Links</label>
										<input type="number	" className="form-control" {...register("cliam_link", {})}  placeholder="Claim Linksus" name="cliam_link"/>
										<p className="text-danger fs-12">{errors.cliam_links?.message}</p>
									</div>
								</div>
								<div className="col-xl-4 col-xxl-6 col-6">
									<div className="form-check custom-checkbox mb-3 checkbox-success">
										<input
										type="checkbox"
										className="form-check-input"
										id="airdrop"
										{...register("airdrop", {})}
										/>
										<label
										className="form-check-label"
										htmlFor="airdrop"
										>
										Airdrop
										</label>
									</div>
								</div>
								<hr />
								<div className="col-lg-12">
									<label htmlFor="comments" className="text-black font-w600">Comments</label>
									<div className="form-group ">
										<textarea
											{...register("comments", {})}
											className="form-control"
											rows="8"
											id="comments"
										></textarea>
									</div>
								</div>
								<hr />
								<div className="col-lg-12">
									<div className="form-group mb-3">
										{ invetmentEditModalValue 
										? (<button value="Edit Investment" disabled={!isDirty || !isValid || isSubmitting} className="submit btn btn-primary" name="submit">Edit Investment</button>)
										: (<button value="Add Investment" disabled={!isDirty || !isValid || isSubmitting} className="submit btn btn-primary" name="submit">Add Investment</button>)

									}
									</div>
								</div>
								
							</div>
						</form>
						<DevTool control={control}  />

					</div>
				</div>
      </Modal>
    </div>
  );
}

export default AddInvestmentModal;
