import { useMutation, useQueryClient } from 'react-query';
import {request} from '../utils/axios';

const claimTokensEdit = (claim)  => {
    // Define the headers object
    return request({ 
        url: '/investments/claim', 
        method: 'put', 
        data: claim,
    })
}


export const useClaimTokensEdit = () => {
    const queryClient = useQueryClient()
    return useMutation(claimTokensEdit, {
        onSuccess: () => Promise.all([  
            queryClient.invalidateQueries('claimTokensData'),
            queryClient.invalidateQueries('InvestmentsData')

        ])
    })
}

export default useClaimTokensEdit;