	import React,{Fragment,useState,useContext, useEffect} from 'react';  
	import { Controller, useForm } from 'react-hook-form';
	import { useClaimTokensSubmit } from '../../../hooks/useClaimTokensSubmit';
	import useInvestnentsData from '../../../hooks/useInvestnentsData';
	import useAllInvestnentsData from '../../../hooks/useAllInvestnentsData';
	import useInvestmentsData from '../../../hooks/useInvestnentsData';
	import CustomDatePicker from './components/DatePicker/CustomDatePicker';

	import { claimTokensModalAtom, claimTokenModalIDAtom } from '../../../utils/jotai';
	import { useAtom, useAtomValue } from 'jotai';
	import { DevTool } from '@hookform/devtools';
	import { formatToThreeDecimalsIfNeeded } from '../../../utils/validations';

	import {
	Button, Modal, Spinner
	} from "react-bootstrap";

	
	const validateNumberSmThenNumber = (value, smallThen) => {
		const parsedValue = parseFloat(value);
		const parsedsmallThen = parseFloat(smallThen);
		if (isNaN(parsedValue) || parsedValue > parsedsmallThen || parsedValue <= 0) {
		return `must be smaller than ${parsedsmallThen}`;
		}
		return true;
	};


	function AddClaimModal() {
	
		const AddClaimform = useForm({
			defaultValues: {
				claim_time:null ,
				ammount: 0,
			},
			mode: 'onChange'
		});
		const { register, control, handleSubmit, formState, reset: resetAddClaimModal, setValue } = AddClaimform;
		const { errors, isDirty, isSubmitted, isValid, isSubmitting, isSubmitSuccessful: isClaimSubmitSuccessful } = formState;
		const { mutate: addClaimTokensSubmit, isError: addClaimTokensSubmitIsError, isSuccess: addClaimTokensSubmitIsSucces, error: addClaimTokensSubmitError } = useClaimTokensSubmit()
		const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError, refetch:useAllInvestmentsDataRefetch } = useAllInvestnentsData();
		const { data: useInvestmentsDataData, isLoading: useInvestmentsDataIsLoading, isError: useInvestmentsDataIsError, error: useInvestmentsDataError } = useInvestnentsData();

		const [claim_project_name, setClaim_project_name] = useState('');
		const [unclaimedTokens, setUnclaimedTokens] = useState('');
		const [tokenLeftToClaim, setTokenLeftToClaim] = useState('');
		const [launchpad, setLaunchpad] = useState('');

		const [showClaimTokensModal, setShowClaimTokensModal] = useAtom(claimTokensModalAtom);
		const  showClaimTokensModalID = useAtomValue(claimTokenModalIDAtom);
		const [showClaimTokensModalErrorMsg, setShowClaimTokensModalErrorMsg] = useState("");
		const [unclaimed_tokens, setUnclaimed_tokens] = useState("");
		const [isDisabledButton, setIsDisabledButton] = useState(false);


		const submitaddClaimTokens	= (formData) => {
			setIsDisabledButton(true);
			const project_id = useAllInvestmentsDataData[showClaimTokensModalID].project_id;
			const launchpad = useAllInvestmentsDataData[showClaimTokensModalID].launchpad;
			const investment_id = showClaimTokensModalID;
			const submit_data = {
				...formData, 
				project_id: project_id, 
				launchpad: launchpad,
				investment_id: investment_id
			}
			submit_data['ammount'] = parseFloat(submit_data['ammount']);
			addClaimTokensSubmit(submit_data)
		};


		useEffect(() => {
			if (addClaimTokensSubmitIsSucces) {
				setShowClaimTokensModal(false);
				setIsDisabledButton(false);
				
			}
			
		}, [addClaimTokensSubmitIsSucces])

		useEffect(() => {
			if (addClaimTokensSubmitIsError) {
				setIsDisabledButton(false);
				setShowClaimTokensModalErrorMsg(addClaimTokensSubmitError.toString());
			}

		}, [addClaimTokensSubmitIsError]);

		useEffect(() => {
			if (showClaimTokensModal && useAllInvestmentsDataData) {
				const tokens = useAllInvestmentsDataData[showClaimTokensModalID].num_of_token;
				const project_id = useAllInvestmentsDataData[showClaimTokensModalID]['project_id']
				const project_obj = useInvestmentsDataData['projects'][project_id]
				const investment_obj  = project_obj['list_of_investments'][showClaimTokensModalID]
				const unclaimed_tokens =  investment_obj['unclaimed_tokens'] !== undefined ? investment_obj['unclaimed_tokens'] : tokens;
				const claimed_tokens = investment_obj['total_claimed'] || 0;
				const tokens_left_to_claim = investment_obj['available_tokens_left_to_claim'] || 0;
				
				setLaunchpad(useAllInvestmentsDataData[showClaimTokensModalID].launchpad);
				setUnclaimedTokens(unclaimed_tokens);
				const project_name = useAllInvestmentsDataData[showClaimTokensModalID].project.project_name;
				const token_left_to_claim_str = `${formatToThreeDecimalsIfNeeded(claimed_tokens)}/${formatToThreeDecimalsIfNeeded(investment_obj.available_tokens_to_claim)}`;
				setUnclaimedTokens(tokens_left_to_claim);
				setClaim_project_name(project_name);
				setTokenLeftToClaim(investment_obj.available_tokens_left_to_claim);
				
				AddClaimform.setValue('ammount', tokens_left_to_claim);
				AddClaimform.setValue('claim_time', new Date());
				
			}
		}, [showClaimTokensModal]);


		const handleShowClaimTokensModal = (value) => {
			setShowClaimTokensModal(value);
		}

		if (useAllInvestmentsDataIsLoading || useInvestmentsDataIsLoading) {
			return (
				<div className="text-center">
					<Spinner animation="border" variant="primary" />
				</div>
			)
		}

	return (
		<div>
			<Modal size="lg" className="modal fade" id="showClaimTokensModal" show={showClaimTokensModal}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Claim</h5>
						<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => handleShowClaimTokensModal(false)}></Button>
					</div>
					<div className="modal-body">
						<form className="comment-form" onSubmit={handleSubmit(submitaddClaimTokens)} noValidate>
							<div className="row" >
								<h4>{claim_project_name}({launchpad})</h4>
								<div className="col-lg-4">
								<div className="form-group mb-3">
										<label htmlFor="tgedate" className="text-black font-w600">Claim Date</label>	
										<div className="form-control">
										<Controller
										name="claim_time"
										control={control}
										rules={{ required: 'Sale time is required' }} 
										render={({field}) => (
											<CustomDatePicker
											selected={field.value}
											onChange={(date) => field.onChange(date)}
										/>
										)}
									/>

										</div>
										<p className="text-danger fs-12">{errors.tgeDate?.message}</p>
									</div>
								</div>
								<div className="col-lg-2">
									<div className="form-group mb-3">
										<label htmlFor="ammount" className="text-black font-w600"> # To Claim<span className="required">*</span></label>
										<input type="text" className="form-control"  name="ammount" { ...register('ammount', {
											required: '* Required',
											validate: {
												validNumber: (value) => validateNumberSmThenNumber(value, unclaimedTokens)
											}
										})} />
										<p className="text-info fs-12">{tokenLeftToClaim} Tokens</p>
										<p className="text-danger fs-12">{errors.ammount?.message}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
									<div className="form-group mb-3">
										<button className="submit btn btn-primary" disabled={!isDirty || !isValid || isSubmitting || isDisabledButton} name="submit">Add Claim </button>
									</div>
								</div>
						</form>
						<DevTool control={control} />
					</div>
				</div>
			</Modal>	
		</div>
	);
	}

	export default AddClaimModal;
