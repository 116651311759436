import React,{Fragment,useState,useContext, useEffect} from 'react';  
import { Controller,set,useForm } from 'react-hook-form';
import { useSoldTokensSubmit } from '../../../hooks/useSoldTokensSubmit';
import useInvestnentsData from '../../../hooks/useInvestnentsData';
import useAllInvestnentsData from '../../../hooks/useAllInvestnentsData';
import { soldTokensModalAtom, soldTokenModalIDAtom } from '../../../utils/jotai';
import { useAtom, useAtomValue } from 'jotai';
import { DevTool } from '@hookform/devtools';
import CustomDatePicker from './components/DatePicker/CustomDatePicker';
import { formatToThreeDecimalsIfNeeded } from '../../../utils/validations';

import {
Button, Modal
} from "react-bootstrap";


const validateNumberSmThenNumber = (value, smallThen) => {
	const parsedValue = parseFloat(value);
	const parsedsmallThen = parseFloat(smallThen);
	if (isNaN(parsedValue) || parsedValue > parsedsmallThen) {
		return `must be smaller than ${parsedsmallThen}`;
	}else if (isNaN(parsedValue)  || parsedValue <= 0) {
		return `must be greater than 0`;
	}
	return true;
};

const validateNumberGtThen0 = (value) => {
	const parsedValue = parseFloat(value);
	if (isNaN(parsedValue)  || parsedValue <= 0) {
		return `must be greater than 0`;
	}
	return true;
};

function AddSaleModal() {
	const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError } = useAllInvestnentsData();
	const [showSoldTokensModal, setShowSoldTokensModal] = useAtom(soldTokensModalAtom);
	const  showSoldTokensModalID = useAtomValue(soldTokenModalIDAtom);
	
	const AddSaleform = useForm({
		defaultValues: {
			sale_time:null ,
			ammount: 0,
			price: 0
		},
		mode: 'onChange'
	});
	const { register, control, watch , handleSubmit, formState, reset: resetAddSaleModal, setValue } = AddSaleform;
	const { errors, isDirty, isSubmitted, isValid, isSubmitting, isSubmitSuccessful: isSaleSubmitSuccessful } = formState;
	const { mutate: AddSaleTokensSubmit, isError: AddSaleTokensSubmitIsError, isSuccess: AddSaleTokensSubmitIsSucces, error: AddSaleTokensSubmitError, isLoading: AddSaleTokensIsLoading} = useSoldTokensSubmit()
	const { data: useInvestmentsDataData, isLoading: useInvestmentsDataIsLoading, isError: useInvestmentsDataIsError, error: useInvestmentsDataError } = useInvestnentsData();

	const [projectName, setProjectName] = useState('');
	const [launchpad, setLaunchpad] = useState('');
	const [unclaimedTokens, setUnclaimedTokens] = useState('');
	const [showSoldTokensModalErrorMsg, setShowSoldTokensModalErrorMsg] = useState("");
	const [profit, setProfit] = useState(0);
	const [buyPrice, setBuyPrice] = useState(0);
	const [profitUsd, setProfitUsd] = useState(0);
	const [unSoldTokens, setUnSoldtokens] = useState("");
	const [tokenLeftToSell, setTokenLeftToSell] = useState("");
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [soldUsd, setSoldUsd] = useState(0);
	const watchedAmount = watch('ammount');
	const watchedPrice = watch('price');

	useEffect(() => {
		
		const formPrice = AddSaleform.getValues('price');
		const formAmount = AddSaleform.getValues('ammount'); // Corrected spelling: ammount -> amount
	
		const calculatedSoldUsd = formPrice * formAmount;
		setSoldUsd(calculatedSoldUsd);
	
		const calculatedProfitUsd = calculatedSoldUsd - (buyPrice * formAmount);
		setProfitUsd(calculatedProfitUsd);
	
		// Ensuring that we do not divide by zero
		const cost = buyPrice * formAmount;
		if (cost > 0) {
			const calculatedProfit = (calculatedProfitUsd / cost) * 100;
			setProfit(formatToThreeDecimalsIfNeeded(calculatedProfit));
		}
	
	}, [watchedAmount, watchedPrice]);
	


	const submitAddSaleTokens = (formData) => {
		setIsButtonDisabled(true);
		const project_id = useAllInvestmentsDataData[showSoldTokensModalID].project_id;
		const launchpad = useAllInvestmentsDataData[showSoldTokensModalID].launchpad;
		const investment_id = showSoldTokensModalID;
		const submitData = {
			...formData, 
			project_id: project_id, 
			launchpad: launchpad,
			investment_id: investment_id

		}
		submitData.price = parseFloat(submitData.price);
		submitData.ammount = parseFloat(submitData.ammount);
		AddSaleTokensSubmit(submitData)
		setIsButtonDisabled(false);
	};

	useEffect(() => {
		if (AddSaleTokensSubmitIsSucces) {
			setShowSoldTokensModal(false);
			AddSaleform.reset();
		}
	}, [AddSaleTokensSubmitIsSucces])

	

	useEffect(() => {
		if (AddSaleTokensSubmitIsError) {
		setShowSoldTokensModalErrorMsg(AddSaleTokensSubmitError.toString());
		}

		if (showSoldTokensModal){
			const tokens = useAllInvestmentsDataData[showSoldTokensModalID].num_of_token;
			const project_id = useAllInvestmentsDataData[showSoldTokensModalID]['project_id']
			const project_obj = useInvestmentsDataData['projects'][project_id]
			const investment_obj  = project_obj['list_of_investments'][showSoldTokensModalID]
			const sold_tokens = investment_obj['total_sold'] !== undefined ? investment_obj['total_sold'] : 0;
			const claimed_tokens = investment_obj['total_claimed'] !== undefined ? investment_obj['total_claimed'] : 0;
			const unSold_tokens = claimed_tokens - sold_tokens
			
			const token_sold = useAllInvestmentsDataData[showSoldTokensModalID].token_sold;
			const saleProfitUsd = ((investment_obj.price)* tokens) - (AddSaleform.getValues('price') * tokens);
			const saleProfit = (saleProfitUsd / (investment_obj.price * tokens)) * 100;
			const token_left_to_sell_str = `${formatToThreeDecimalsIfNeeded(sold_tokens)}/${formatToThreeDecimalsIfNeeded(investment_obj.available_tokens_left_to_sell)}`;
			sold_tokens == claimed_tokens ? setIsButtonDisabled(true): setIsButtonDisabled(false);
				
			
			setProjectName(investment_obj.project.project_name);
			setLaunchpad(investment_obj.launchpad);
			setUnSoldtokens(unSold_tokens)
			setTokenLeftToSell(investment_obj.available_tokens_left_to_sell)
			setBuyPrice(investment_obj.price)
			AddSaleform.setValue('price', investment_obj.price);
			AddSaleform.setValue('sale_time', new Date());
			AddSaleform.setValue('ammount', investment_obj.available_tokens_left_to_sell);
			setSoldUsd(investment_obj.price * watchedAmount);
			setProfitUsd((investment_obj.price *  AddSaleform.getValues('ammount')) - (buyPrice * AddSaleform.getValues('ammount')));
			setProfit((profitUsd / (buyPrice * AddSaleform.getValues('ammount'))) * 100);
			
		}


	}, [AddSaleTokensSubmitIsError, showSoldTokensModal]);


	const handleCloseShowSoldTokensModal = () => () => {
		setShowSoldTokensModal(false);
		setIsButtonDisabled(false);
	}


return (
	<div>
		<Modal size="xl" className="modal fade" id="showSoldTokensModal" show={showSoldTokensModal}>
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title">Add Sale</h5>
					<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={handleCloseShowSoldTokensModal()}></Button>
				</div>
				<div className="modal-body">
					<p className="text-danger fs-12">{showSoldTokensModalErrorMsg}</p>
					<form className="comment-form" onSubmit={handleSubmit(submitAddSaleTokens)} noValidate>
						<div className="row" >
							<h4>{projectName} ({launchpad})</h4>
							<div className="col-lg-4">
							<div className="form-group mb-3">
								<label htmlFor="tgedate" className="text-black font-w600">TGE DTE</label>	
								<div className="form-control">
								<Controller
								name="sale_time"
								control={control}
								rules={{ required: 'Sale time is required' }} 
								render={({field}) => (
									<CustomDatePicker
									selected={field.value}
									onChange={(date) => field.onChange(date)}
									/>
									)}
								/>

								</div>
								<p className="text-danger fs-12">{errors.tgeDate?.message}</p>
							</div>
							</div>
							<div className="col-lg-2">
								<div className="form-group mb-3">
									<label htmlFor="ammount" className="text-black font-w600">Token Sold<span className="required">*</span></label>
									<input type="number" className="form-control"  name="ammount" { ...register('ammount', {
										required: '* Required',
										validate: {
											validNumber: (value) => validateNumberSmThenNumber(value, unSoldTokens)
										},
										valueAsNumber: true
									})} />
									<p className="text-info fs-12">{tokenLeftToSell} Tokens</p>
									<p className="text-danger fs-12">{errors.ammount?.message}</p>
								</div>
							</div>
							<div className="col-lg-2">
								<div className="form-group mb-3">
									<label htmlFor="price" className="text-black font-w600"> Price<span className="required">*</span></label>
									<input type="number" className="form-control"  name="price" { ...register('price', {
										required: '* Required',
										validate: {
											validNumber: (value) => validateNumberGtThen0(value)
										},
										valueAsNumber: true
									})} />
									<p className="text-info fs-12">Buy price: {formatToThreeDecimalsIfNeeded(buyPrice, 4)}</p>
									<p className="text-danger fs-12">{errors.price?.message}</p>
								</div>
							</div>

							<div className="col-lg-1">
								<div className="form-group mb-2">
									<label htmlFor="dollarSold" className="text-black font-w600"> $ Sold<span className="required">*</span></label>
									<p>${formatToThreeDecimalsIfNeeded(soldUsd)}</p>
								</div>
							</div>
							<div className="col-lg-1">
								<div className="form-group mb-2">
									<label htmlFor="dollarProfit" className="text-black font-w600"> $ Profit<span className="required">*</span></label>
									<p>${formatToThreeDecimalsIfNeeded(profitUsd)}</p>
								</div>
							</div>
							<div className="col-lg-1">
								<div className="form-group mb-2">
									<label htmlFor="ammounprecentProfitt" className="text-black font-w600"> % Profit<span className="required">*</span></label>
									<p>{profit}%</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="form-group mb-3">
								<button className="submit btn btn-primary" disabled={!isDirty || !isValid || isSubmitting || isButtonDisabled} name="submit">Add Sale </button>
							</div>
						</div>
					</form>
					<DevTool control={control} />
				</div>
			</div>
		</Modal>	
	</div>
);
}

export default AddSaleModal;
