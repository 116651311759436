import { useMutation, useQueryClient } from 'react-query';
import {request} from '../utils/axios';

const claimTokensDelete = (claim)  => {
    // Define the headers object
    return request({ 
        url: '/investments/claim', 
        method: 'delete', 
        data: claim,
    })
}


export const useClaimTokensDelete = () => {
    const queryClient = useQueryClient()
    return useMutation(claimTokensDelete, {
        onSuccess: () => {
            queryClient.invalidateQueries('claimTokensData')
            queryClient.invalidateQueries('InvestmentsData')
            console.log('Investment Deleted Successfully!!!!!!!!!!!!!!!!!')
        }
    })
}

export default useClaimTokensDelete;