import { useQuery } from 'react-query';
import {request} from '../utils/axios';

const fetchInvestmentsData = () => {
    // Define the headers object
    return request({ url: '/investments/investment/sum/investment', method: 'get' })
}


const useInvestmentsData = () => {
    return useQuery(
        'InvestmentsData', 
        fetchInvestmentsData,
        {
            staleTime: 300 * 1000, // 300 sec 
            select: (data) => {
                return data.data.data;
            }          
        },
        
    );
}

export default useInvestmentsData;