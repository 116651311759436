import React,{Fragment, useEffect, useState} from 'react';
import useProjectsData from "../../../../../hooks/useProjectsData"
import { invetmentEditClaiValuemAtom } from '../../../../../utils/jotai';
import { useAtom } from 'jotai';
import DeleteClaimModal from "../../DeleteClaimModal";
import ClaimTokeRow from "./ClaimTokeRow";
import ClaimTokeRowEditable from "./ClaimTokeRowEditable"
import { useForm } from 'react-hook-form';
import useClaimTokensEdit from '../../../../../hooks/useClaimTokensEdit';


import {
  Col,
  Card,
  Table,
  Spinner
} from "react-bootstrap";


const ClaimTokensTable = (data) => {
    const { data: useProjectsDataData, isLoading: useProjectsDataIsLoading, isError: useProjectsDataIsError, error: useProjectsDataError } = useProjectsData();
    const [invetmentEditClaiValue, setInvetmentEditClaiValuemAtom] = useAtom(invetmentEditClaiValuemAtom);
    const { mutate: EditTokensSubmit, isError: EditTokensSubmitIsError, isSuccess: EditTokensSubmitIsSucces, error: EditTokensSubmitError, isLoading:EditTokensSubmitIsLoading } = useClaimTokensEdit()
    const [editErrorMsg, setEditErrorMsg] = useState(null);

    const EditClaimform = useForm({
        mode: 'onChange',

    });

    const { register, control, handleSubmit, formState, reset: resetEditClaim, setValue } = EditClaimform;

    const rows = Object.entries(data.data || {}).map(([key, value]) => ({
        id: value._id,
        amount: value.ammount,  // note the misspelling in 'ammount' s
        claim_time: value.claim_time,
        project_id: value.project_id,
        launchpad: value.launchpad,
        user_id: value.user_id,
    }));

    useEffect(() => {
        if (EditTokensSubmitIsError) {
            setEditErrorMsg(EditTokensSubmitError.message);
        }

    },[EditTokensSubmitIsError])

    useEffect(() => {
        if (EditTokensSubmitIsSucces) {
            setInvetmentEditClaiValuemAtom(null);
        }
    }, [EditTokensSubmitIsSucces]);


 

    const handleEditClaim = (formData) => {
        const postData = {
            ...formData,
            id: invetmentEditClaiValue,
            claim_time : formData.claim_time.toISOString()
        }
        postData.ammount = parseFloat(postData.ammount)
        EditTokensSubmit(postData)
        
        
    }


    return(
        <Fragment>
            <form onSubmit={handleSubmit(handleEditClaim)} noValidate>
                <DeleteClaimModal />
                <Col lg={12}>
                <Card>
                <Card.Header>
                <p className="text-danger fs-12">{editErrorMsg}</p>

                </Card.Header>
                {EditTokensSubmitIsLoading
                ? (<div className="text-center"><Spinner animation="border" variant="primary" />Loading</div>)
                : (   
                <Card.Body>
                    <Table responsive>
                    <thead>
                        <tr>
                        <th>
                            <strong>Project</strong>
                        </th>
                        <th>
                            <strong>Launchpad</strong>
                        </th>
                        <th>
                            <strong>Amount</strong>
                        </th>
                        <th>
                            <strong>Date</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows?.map((row) => ( 
                                    <Fragment>
                                        {
                                        invetmentEditClaiValue === row.id 
                                        ?   (<ClaimTokeRowEditable formMethods={EditClaimform}  row={row} projectName={useProjectsDataData[row.project_id].project_name } />)
                                        :   (<ClaimTokeRow row={row} projectName={useProjectsDataData[row.project_id].project_name} />)
                                        }
                                    </Fragment>
                                    
                                )
                            )

                        }
                    </tbody>
                    </Table>
                </Card.Body>
                )
                }
                </Card>
                </Col>
            </form>
        </Fragment>
    );
};

export default ClaimTokensTable;