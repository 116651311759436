import React,{Fragment,useRef,useContext, useEffect, useReducer} from 'react';
import { Link } from "react-router-dom";
import { useAtom, useAtomValue } from 'jotai';
import {invetmentEditClaimStatusAtom, invetmentEditClaiValuemAtom} from '../../../../../utils/jotai';
import useClaimTokensEdit from '../../../../../hooks/useClaimTokensEdit';
import useClaimTokensData from '../../../../../hooks/useClaimTokensData';
import { Button } from 'react-bootstrap';
import { Tooltip } from '@chakra-ui/react'
import { Controller } from 'react-hook-form';

import CustomDatePicker from '../DatePicker/CustomDatePicker';


const validateNumberSmThenNumber = (value, smallThen=null) => {
    const parsedValue = parseFloat(value);
    const parsedsmallThen = parseFloat(smallThen);
    if(smallThen === null && parsedValue <= 0) {
        return `must be bigger than 0}`; 
    }
    else if (isNaN(parsedValue) || parsedValue > parsedsmallThen) {
        return `must be smaller than ${parsedsmallThen}`;
    }
    return true;
};


const ClaimTokeRowEditable = ({ row, projectName, formMethods}) => {
    const [invetmentEditClaiValue, setInvetmentEditClaiValue] = useAtom(invetmentEditClaiValuemAtom);
    const { mutate: EditTokensSubmit, isError: EditTokensSubmitIsError, isSuccess: EditTokensSubmitIsSucces, error: EditTokensSubmitError } = useClaimTokensEdit()
    const { data: useClaimTokensDataData, isLoading: useClaimTokensDataIsLoading, isError: useClaimTokensDataIsError, error: useClaimTokensDataError, refetch: useClaimTokensDataRefetch } = useClaimTokensData();
    const tooltipColor = 'red'

    const { register, control, handleSubmit, formState, reset, setValue, errors } = formMethods;
  //  formMethods.setValue('claim_time', new Date(row.claim_time))

    const handleCloseEditClaim = () => {
        setInvetmentEditClaiValue(null);
    }

    const handleEditClaimSubmit = () => {
        const claim = useClaimTokensDataData[invetmentEditClaiValue];
        EditTokensSubmit(null);
    }
    useEffect(() => {
        formMethods.reset()
    },[])

    
return (

    <tr key={row.id}>
        <td>
            <div className="d-flex align-items-center">
                <span className="w-space-no">{projectName}</span>
            </div>
        </td>
        <td>{row?.launchpad}</td>
        <td>
            <input type="number" className="form-control" defaultValue={row.amount}  name="ammount" { ...register('ammount', {
                required: '* Required',
                validate: {
                    validNumber: (value) => validateNumberSmThenNumber(value)
                },
                valueAsNumber: true,
            })} />
        </td>
        <td>
        <div className="form-group mb-3">
            <div className="form-control">
            <Controller
                name="claim_time"
                defaultValue={new Date(row.claim_time)}
                control={control}
                render={({field}) => (
                    <CustomDatePicker
                    
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                />
            )}
        />

            </div>
        </div>
        </td>
        <td>
            <div className="d-flex">
            <Tooltip label='Approve' placement='top' color={tooltipColor}>
                <button
                    name="submit"
                    className="btn btn-success shadow btn-xs sharp me-1"
                >
                <i className="fa fa-check"></i>
                </button>
            </Tooltip>  
            <Tooltip label='Cancel' placement='top' color={tooltipColor}>
                <Link
                    onClick={handleCloseEditClaim}
                    value={row["_id"]}
                    href="#"
                    className="btn btn-danger shadow btn-xs sharp"
                >
                    <i className="fa fa-close"></i>
                </Link>
            </Tooltip>
            </div>
            </td>
        </tr>
    )
}


export default ClaimTokeRowEditable;