import React, { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { projectNameFildValueAtom, selectedProjecstDataAtom, showProjectFilterSuggestionsAtom } from '../../../../../utils/jotai';
import './AutoCompleteStyle.css'

const AutoComplete = ({ suggestions }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [projectNameFildValue, setProjectNameFildValue ] = useAtom(projectNameFildValueAtom);
    const [selectedProjecstData, setSelectedProjecstData ] = useAtom(selectedProjecstDataAtom);
    const [showProjectFilterSuggestions, setshowProjectFilterSuggestions ] = useAtom(showProjectFilterSuggestionsAtom);


    useEffect(() => {
        if (projectNameFildValue) {

            const newFilteredSuggestions = suggestions.filter(
                suggestion => suggestion.toLowerCase().includes(projectNameFildValue.toLowerCase())
            );
            setFilteredSuggestions(newFilteredSuggestions);
        } else {
            setFilteredSuggestions([]);
        }
        setInputValue(projectNameFildValue || '');
    }, [projectNameFildValue, suggestions]);

    const handleAutoCompleteClick = (suggestion) => () => { 
        setSelectedProjecstData([suggestion]);
    }    


    return (
        <div>
            {filteredSuggestions.length > 0 && showProjectFilterSuggestions &&(
                <ul className="autocomplete-dropdown">
                    {filteredSuggestions.map(suggestion => (
                        <li 
                            key={suggestion}
                            onClick={handleAutoCompleteClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutoComplete;
