import React,{Fragment,useState,useContext, useEffect} from 'react';
import { ThemeContext } from "../../../context/ThemeContext";
import Investments from "./data/investments"
import AddInvestmentModal from './AddInvestmentModal'
import  AddSaleModal  from './AddSaleModal'
import AddClaimModal from './AddClaimModal';

const Home = () => {

	// add investment data hook
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
	}, []);
		return(
			<Fragment>
				<AddInvestmentModal />
				<AddClaimModal />
				<AddSaleModal />
				<Investments/>
			</Fragment>			
		)
	
}
export default Home;