export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="flaticon-144-layout"></i>,
        content: [
            
            {
                title: 'Dashboard',
                to: '/vesting-dashboard',					
            },
            {
                title: 'Next Vesting',
                to: '/next-vesting',
            }
        ]
    }
]