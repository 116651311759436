import React,{Fragment,useState,useContext, useEffect} from 'react';  
import { Accordion } from 'react-bootstrap';
import BootstrapTable  from '../components/BootstrapTable/BootstrapTable';
import useInvestnentsData from '../../../../hooks/useInvestnentsData';
import useAllInvestnentsData from '../../../../hooks/useAllInvestnentsData';
import useClaimTokensData from '../../../../hooks/useClaimTokensData';
import useSoldTokensData from '../../../../hooks/useSoldTokensData';
import ClaimTokensTable from '../components/BootstrapTable/ClaimTokensTable'
import SoldTokensTable from '../components/BootstrapTable/SoldTokensTable'
import useProjectsData from "../../../../hooks/useProjectsData"
import DeleteInvestmentModal from "../DeleteInvestmentModal"
import CustomProgressBar from '../components/ProgressBar/ProgressBar';
import StatCard from '../components/Card/StatCard/Card';
import useMeData from '../../../../hooks/useMe';
import './investments.css';

import { formatToThreeDecimalsIfNeeded } from '../../../../utils/validations';

import { investmentModalAtom, filterProjecstDataAtom, filterProjectSymbolDataAtom } from '../../../../utils/jotai';
import { useAtom } from 'jotai';
import {
  Card, Col,Row,  Tab, Nav, Button, Modal, Spinner
} from "react-bootstrap";
import { set } from 'react-hook-form';
import { Divider } from '@chakra-ui/react';


function Investments() {
  
  const { data: useInvestmentsDataData, isLoading: useInvestmentsDataIsLoading, isError: useInvestmentsDataIsError, error: useInvestmentsDataError, isSuccess:useInvestmentsDataIsSuccess } = useInvestnentsData();
  const { data: useClaimTokensDataData, isLoading: useClaimTokensDataIsLoading, isError: useClaimTokensDataIsError, error: useClaimTokensDataError } = useClaimTokensData();
  const { data: useSoldTokensDataData, isLoading: useSoldTokensDataIsLoading, isError: useSoldTokensDataIsError, error: useSoldTokensDataError } = useSoldTokensData();
  const { data: useProjectsDataData, isLoading: useProjectsDataIsLoading, isError: useProjectsDataIsError, error: useProjectsDataError, isSuccess: useProjectsDataIsSuccess } = useProjectsData();
  const { data: useAllInvestmentsDataData, isLoading: useAllInvestmentsDataIsLoading, isError: useAllInvestmentsDataIsError, error: useAllInvestmentsDataError } = useAllInvestnentsData();
  const { data: meProfileData , isError: meProfileIsError, isSuccess: meProfileIsSucces, error: meProfileError, data:meProfileIsData, isLoading:meProfileIsLoading } = useMeData()

  const [showAddInvestmentModalAtom, setShowAddInvestmentModalAtom] = useAtom(investmentModalAtom);
  const [filterProjecstData, setFilterProjecstData] = useAtom(filterProjecstDataAtom);
  const [filterProjectSymbolData, setFilterProjectSymbolData] = useAtom(filterProjectSymbolDataAtom);
  const [profitCollor, setProfitCollor] = useState({});
  const [defaultAccordion, setDefaultAccordion] = useState([]);
  const [searchItem, setSearchItem] = useState('')
  const [filteredAccordion, setFilteredAccordion] = useState(defaultAccordion); // New state for filtered data

  useEffect(() => {
    // This effect runs whenever defaultAccordion changes, to ensure filteredAccordion is reset correctly
    setFilteredAccordion(defaultAccordion);
  }, [defaultAccordion]);

  useEffect(() => {
    if (useInvestmentsDataIsSuccess && useInvestmentsDataData && useInvestmentsDataData.projects) {
      const projectList = Object.values(useInvestmentsDataData.projects || {}).map(item => (
        `${item.project_name}::${item.project_symbol}`
      ));

      setFilterProjecstData(projectList)
      setFilterProjectSymbolData(projectList)
    }

    if (useInvestmentsDataData && useProjectsDataData) {
      getDefaultAccordion(useProjectsDataData, useInvestmentsDataData)
    }


  }, [useInvestmentsDataData, useProjectsDataData]);
  
  
  const handleAddInvestmentModal = (show) => () => {
    setShowAddInvestmentModalAtom(show)
  };


const handleInputSearchChange = (e) => {
  const searchTerm = e.target.value.toLowerCase();
  setSearchItem(searchTerm);

  if (searchTerm === "") {
    setFilteredAccordion(defaultAccordion); // Reset to original data if search term is cleared
  } else {
    const filteredItems = defaultAccordion.filter((item) => {
      return item.title.project_name.toLowerCase().includes(searchTerm);
    });

    setFilteredAccordion(filteredItems); // Update state with filtered items
  }

  console.log('searchTerm', searchTerm);
  console.log('filteredItems', filteredAccordion);
  // No need to directly modify defaultAccordion here
};

  


  function getVerifiedProjectsDict(data){
    let tempDict = {}

    Object.entries(data).map(([key, value]) => {
      if (value.verified) {
        tempDict[value.token_address] = value;
      }
    });

    return tempDict;
  }

// Update the state with the dictionary of verified projects
//setVerifiedProjects(verifiedProjectsDict);

function getDefaultAccordion(projectsData, investmentsData) {
  const verifiedProjectsDictTemp = getVerifiedProjectsDict(projectsData);
  let tempDict = []
  Object.keys(investmentsData.projects || {}).forEach((projectId) => {
    const summaryDetails = {...investmentsData.projects[projectId]}
    const investments = investmentsData.projects[projectId].list_of_investments;

    const project_address = summaryDetails.project_address;
    let projectMetrics = {}
    const verifiedProjectDetails = verifiedProjectsDictTemp[project_address] || {};
    if (verifiedProjectDetails && verifiedProjectDetails.metrics) {
       projectMetrics = verifiedProjectDetails.metrics
       projectMetrics['price_dif'] = parseFloat((projectMetrics.price - summaryDetails.avarage_price) /summaryDetails.avarage_price * 100);
    }
    summaryDetails['metrics'] = projectMetrics || {};
    parseFloat(projectMetrics.price) > parseFloat(summaryDetails.avarage_price) ? summaryDetails.profitColor = 'text-success' : summaryDetails.profitColor = 'text-danger';
    delete summaryDetails.list_of_investments;
    tempDict.push({
      title: summaryDetails,
      text: investments,
      bg: 'primary',

    });
  });

  setDefaultAccordion(tempDict);
};

if (useInvestmentsDataIsLoading || useAllInvestmentsDataIsLoading ||useClaimTokensDataIsLoading || useSoldTokensDataIsLoading || useProjectsDataIsLoading || meProfileIsLoading ) {
  return <div className="text-center"><Spinner animation="border" variant="primary" />Loading</div>;
}

if (useInvestmentsDataIsError) {
  return <p>Error: {useInvestmentsDataError.message}</p>;
}

  return (
    <div>
      <DeleteInvestmentModal />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>Vesting Tracker</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <StatCard  iconClass="flaticon-381-heart" category="Projects" wide={2} value={useInvestmentsDataData.invested_projects} />
              <StatCard  iconClass="flaticon-381-diamond" category="Investments" wide={2} value={useInvestmentsDataData.total_investments} />
              <StatCard  iconClass="flaticon-381-heart" category="Invested" wide={3} value={`$${useInvestmentsDataData.total_invested_usd}`} />
              <StatCard  iconClass="la la-dollar" category="Profit" wide={3} value={`$${useInvestmentsDataData.total_profit}`} />
            </Row>
            <Tab.Container defaultActiveKey="Projects">
                <Row className='col-12'>
                <div className="col-lg-6">
                  <Nav as="ul" className="col-12 nav-pills mb-4 justify-content-start">
                    <Nav.Item as="li" key="1">
                        <Nav.Link eventKey="Projects">
                          Projects
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" key="2">
                      <Nav.Link eventKey="Claims">
                        Claims
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" key="3">
                      <Nav.Link eventKey="Sales">
                      Sales
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                    
                </div>
                <div className=" col-6 d-flex justify-content-end add-investment-btn">
                    <Button as="a" href="#" className="col-4 btn btn-primary" onClick={handleAddInvestmentModal(true)}>Add Investment</Button>
                    <div className="form-group col-5">
                      <input type="text" className="form-control" name="projectName" placeholder="Search" onChange={handleInputSearchChange} />
                    </div>
                  </div>  
                    </Row>

                  <Tab.Content className='investments-tab'>
                      <Tab.Pane eventKey="Projects" key="1">
                        
                        <Accordion className="accordion accordion-primary">
                          {filteredAccordion.map((d, i) => (
                            <Accordion.Item className="accordion-item" key={i} eventKey={`${i}`}>
                              <Accordion.Header className="accordion-header rounded-lg">
                              <div className="text-center border-bx col-12">
    {/* First Line */}
    <div className="d-flex justify-content-between mb-2">
        <div className="text-center border-bx me-5">
            <span>Project</span>
            <p className="mb-0 pt-1 font-w500 text-black">{d.title.project_name}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>Symbol</span>
            <p className="mb-0 pt-1 font-w500 text-black">{d.title.project_symbol}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>Chain</span>
            <p className="mb-0 pt-1 font-w500 text-black">{d.title.chain}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>Investments</span>
            <p className="mb-0 pt-1 font-w500 text-black">{d.title.num_of_investments}</p>
        </div>

        <div className="text-center border-bx me-5">
            <span>Tokens</span>
            <p className="mb-0 pt-1 font-w500 text-black">{formatToThreeDecimalsIfNeeded(d.title.total_num_of_token)}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>Claimed</span>
            <p className="mb-0 pt-1 font-w500 text-black">{formatToThreeDecimalsIfNeeded(d.title.total_claimed)}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>Sold</span>
            <p className="mb-0 pt-1 font-w500 text-black">{formatToThreeDecimalsIfNeeded(d.title.total_sold)}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>$Invested</span>
            <p className="mb-0 pt-1 font-w500 text-black">{formatToThreeDecimalsIfNeeded(d.title.total_invested_usd)}</p>
        </div>
        <div className="text-center border-bx me-5">
            <span>Avg. Price</span>
            <p className="mb-0 pt-1 font-w500 text-black">{formatToThreeDecimalsIfNeeded(d.title.avarage_price, 4)}</p>
        </div>
        </div>
        {/* Second Line */}

        {d.title.metrics.price && (
        <Fragment>
            <hr />
            <div className="d-flex justify-content-between">
                <div className="text-center border-bx me-5">
                    <span className={`mb-0 pt-1 font-w500 ${d.title.profitColor}` }>Price</span>
                    <p className={`mb-0 pt-1 font-w500 ${d.title.profitColor}` }>${formatToThreeDecimalsIfNeeded(parseFloat(d.title.metrics.price), 2)}</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span className={`mb-0 pt-1 font-w500 ${d.title.profitColor}` }>Change</span>
                    <p className={`mb-0 pt-1 font-w500 ${d.title.profitColor}` }>{formatToThreeDecimalsIfNeeded(d.title.metrics.price_dif, 2)}%</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >R. Profit</span>
                    <p className="mb-0 pt-1 font-w500">N/A</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >UR. Profit</span>
                    <p className="mb-0 pt-1 font-w500">N/A</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >FDV</span>
                    <p className="mb-0 pt-1 font-w500">${formatToThreeDecimalsIfNeeded(d.title.metrics.fdv)}</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >Liquidity</span>
                    <p className="mb-0 pt-1 font-w500">${formatToThreeDecimalsIfNeeded(d.title.metrics.liquidity.usd, 0)}</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >24H Price</span>
                    <p className="mb-0 pt-1 font-w500">{formatToThreeDecimalsIfNeeded(d.title.metrics.price_change.h24, 2)}%</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >24H Sells/Buys</span>
                    <p className="mb-0 pt-1 font-w500">{d.title.metrics.txns.h24.sells}/{d.title.metrics.txns.h24.buys}</p>
                </div>
                <div className="text-center border-bx me-5">
                    <span >% 24H Volume</span>
                    <p className="mb-0 pt-1 font-w500">${formatToThreeDecimalsIfNeeded(d.title.metrics.volume.h24, 2)}</p>
                </div>
                <div className="text-center border-bx me-5">
                  <a className="mb-0 pt-1 font-w500" href={d.title.metrics.url} target="_blank" ><i className="fa fa-3x fa-area-chart text-success" ></i></a>
                </div>
                {/* Include other items for the second line if needed */}
            </div>
        </Fragment>
    )}  
</div>

                                </Accordion.Header>
                                <Accordion.Collapse eventKey={`${i}`}>
                                  <div className="accordion-body">
                                  <Row >
                                    <Col xl={6} lg={6} md={6}>
                                      <Card>  
                                          <Card.Body>
                                            <CustomProgressBar start={0} end={d.title.total_num_of_token} available={d.title.total_available_tokens_to_claim} current={d.title.total_claimed} title='Claimed Tokens'/>
                                          </Card.Body>
                                    </Card>
                                  </Col>
                                  <Col xl={6} lg={6} md={6}>
                                    <Card>
                                        <Card.Body>
                                          <CustomProgressBar  start={0} end={d.title.total_num_of_token} available={d.title.total_available_tokens_to_sell} current={d.title.total_sold} variant='success' title='Sold Tokens'/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                </Row>
                                <BootstrapTable data={d.text} titleData={d.title} />

                                </div>
                              </Accordion.Collapse>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Claims" key="2">
                      {useClaimTokensDataIsLoading && <p>Loading...</p>}
                      {useClaimTokensDataIsError && 
                        <p className="text-danger fs-12">Error: {useClaimTokensDataError.message}</p>
                      }
                      {Array.isArray(useClaimTokensDataData) && useClaimTokensDataData.length === 0 && <p>No data</p>}
                      { !useAllInvestmentsDataIsLoading && !useProjectsDataIsLoading &&
                      <ClaimTokensTable data={useClaimTokensDataData} />
                      }
                      </Tab.Pane>
                      <Tab.Pane eventKey="Sales" key="3">
                      {useSoldTokensDataIsLoading && <p>Loading...</p>}
                      {useSoldTokensDataIsError && 
                        <p className="text-danger fs-12">Error: {useSoldTokensDataError.message}</p>
                      }
                      { Array.isArray(useSoldTokensDataData) && useSoldTokensDataData.length === 0 && <p>No data</p>}
                      { !useAllInvestmentsDataIsLoading && !useProjectsDataIsLoading &&
                        <SoldTokensTable data={useSoldTokensDataData} />
                      }
                      </Tab.Pane>
                  </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Col>

    </div>
  );
}

export default Investments;
