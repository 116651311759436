import React from 'react';
import './Card.css' 

const formatNumber = (value) => {
    const hasDollarSign = typeof value === 'string' && value.includes('$');
    const num = hasDollarSign ? parseFloat(value.replace('$', '')) : value;

    const formatWithCommas = (number, decimalPlaces) => {
        return number.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    let formattedValue;
    if (num >= 1000 && num < 1000000) { // Thousands
        formattedValue = `${formatWithCommas(num / 1000, 2)}K`;
    } else if (num >= 1000000) { // Millions
        formattedValue = `${formatWithCommas(num / 1000000, 2)}M`;
    } else {
        // For numbers less than 1000, no decimals unless the number has 5 digits
        formattedValue = formatWithCommas(num, num >= 10000 ? 2 : 0);
    }

    return hasDollarSign ? `$${formattedValue}` : formattedValue;
};

const StatCard = ({ iconClass, category, value, wide=3 }) => {
    const formattedValue = formatNumber(value);

    return (
        <div className={`col-xl-${wide} col-xxl-${wide} col-lg-${wide} col-sm-${wide}`}>
            <div className="widget-stat card bg-primary">
                <div className="card-body p-2">
                <p className="mb-1 text-white category-text">{category}</p>

                    <div className="media">
                        <span className="me-3 stat-card-icon" >
                            <i className={iconClass}></i>
                        </span>
                        <div className="media-body text-white text-right ">
                            <h3 className="text-white">{formattedValue}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatCard;