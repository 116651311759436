import { useQuery } from 'react-query';
import {request} from '../utils/axios';

const fetchAllInvestmentsData = () => {
    // Define the headers object
    return request({ url: '/investments/investment', method: 'get' })
}


const useAllnvestmentsData = () => {
    return useQuery(
        'AllInvestmentsData', 
        fetchAllInvestmentsData,
        {
            staleTime: 30000, // 30 sec 
            select: (data) => {
                return data.data.data;
            }          
        },
        
    );
}

export default useAllnvestmentsData;