import { useMutation, useQueryClient } from 'react-query';
import {request} from '../utils/axios';

const investmentDelete = (investment)  => {
    // Define the headers object
    return request({ 
        url: '/investments/investment', 
        method: 'delete', 
        data: investment,
    })
}


export const useInvestmentDelete = () => {
    const queryClient = useQueryClient()
    return useMutation(investmentDelete, {
        onSuccess: () => {
            queryClient.invalidateQueries('soldTokensData')
            queryClient.invalidateQueries('InvestmentsData')
            console.log('Investment Deleted Successfully!!!!!!!!!!!!!!!!!')
        }
    })
}

export default useInvestmentDelete;