import axios from "axios";

const client = axios.create({ baseURL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_PREFIX}` });

export const request = async ({ ...options }) => {
  try {
    client.defaults.headers.common.Authorization = `Bearer ${JSON.parse(localStorage.getItem('userDetails')).idToken}`;
    client.defaults.headers.common['Content-Type'] = 'application/json';

    const response = await client(options);
    console.log('AXIOS !!!!! Request Successful!!!', response.config.url);
    return response;
  } catch (error) {
    if (error.response) {
      console.error('AXIOS !!!!! Request Failed:', error.response.config.url);
      console.error('AXIOS !!!!! Status:', error.response.status);
      console.error('AXIOS !!!!! Data:', error.response.data);
      console.error('AXIOS !!!!! Headers:', error.response.headers);
      throw new Error(error.response.data.message); 
    }
    throw new Error(error);
  }
};


export const nonSecureRequest = async ({ ...options }) => {
  try {
    client.defaults.headers.common['Content-Type'] = 'application/json';

    const response = await client(options);
    console.log('AXIOS !!!!! Request Successful!!!', response.config.url);
    return response;
  } catch (error) {
    if (error.response) {
      console.error('AXIOS !!!!! Request Failed:', error.response.config.url);
      console.error('AXIOS !!!!! Status:', error.response.status);
      console.error('AXIOS !!!!! Data:', error.response.data);
      console.error('AXIOS !!!!! Headers:', error.response.headers);
      throw new Error(error.response.data.message); 
    }

    throw new Error(error.response.data);
  }
};